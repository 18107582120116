import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Quiz from "../../../images/quiz.png";
import appContext from "../../../context/appContext";
import Bell from "../../../images/bell.png";
import Quizzes from "../../../images/Quizzes.png";
import ClassroomIcon from "../../../images/Classroom.png";
import Info from "../../../images/info-classroom.png";
import moment from "moment";

function StudentClassroomsCard() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var id = params.get("id");
  const [studentClassroomData, setStudentClassroomData] = useState();
  const navigate = useNavigate();
  const state = useContext(appContext);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!state.openJoinClassroomDialogue) {
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["authorization"] = token;
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/student_dashboard", {
          method: "POST",
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          current_date_retrieval: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        })
        .then((response) => {
          setIsLoading(false);
          console.log("RESPONSE IS: ", response.data);
          console.log("ID IS: ", id);
          if (id !== null) {
            let classroom = response.data.filter((obj) => obj.classroom_id === parseInt(id));
            console.log("andar aya", classroom);

            handleViewClass(classroom[0]);
          }
          setStudentClassroomData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const handleViewClass = (classroom) => {
    navigate("/student-view-classroom", {
      state: {
        studentClassroomData: classroom,
      },
    });
  };
  const viewQuiz = (quiz) => {
    localStorage.setItem("quiz_taker", 1);
    console.log("called", quiz);
    const quizURL = window.location.origin + "/quiz?id=" + quiz.secret_key;
    window.open(quizURL, "_blank");
  };

  console.log("THE DATA IS: ", studentClassroomData);

  return (
    <div>
      <div className="mt-[42px] ">
        {isLoading && (
          <div className="flex items-center justify-center mt-[15%] ml-[-15%]   w-full">
            <div role="status">
              <svg
                aria-hidden="true"
                class="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="items-center sm:p-[2%] ">
          <div className="grid xlg:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:gap-2 md:gap-4 mt-0  md:w-[70%] xs:ml-[10px] xs:mr-[10px]">
            {studentClassroomData && typeof studentClassroomData !== "string" ? (
              <>
                {studentClassroomData.map((item) => (
                  <div className="md:w-[10%%]">
                    <div className="border-[#DEDDE1] border-2		p-3 bg-[#EFEFEF] rounded-lg	">
                      <div className="flex justify-end w-full">
                        <div className={`${item.attempted_quizzes < item.total_quizzes ? "visible" : "hidden"}`}>
                          <img style={{ width: "20px" }} src={Bell} />
                        </div>
                        <div>
                          <img style={{ width: "20px" }} src={Quizzes} />
                        </div>
                        <div className="flex items-center text-xs	text-[#6750A4] ml-[1%]">
                          Quizzes {item.attempted_quizzes + "/" + item.total_quizzes}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div>
                          <img className="w-[30px]" src={ClassroomIcon} />
                        </div>
                        <div className="ml-[3%] text-xl	font-medium	whitespace-nowrap overflow-hidden text-ellipsis !text-[#1D192B]">
                          {item.classroom_name}
                        </div>
                      </div>
                      <div className="mt-[2%] font-medium text-[#49454F] ">{item.subject_name}</div>
                      <div className="mt-[2%] font-medium text-[#49454F]">Class code: {item.classroom_code}</div>
                      <div
                        className={`flex hidden !pl-4 !pr-4 pt-1 pb-1   border-2 w-fit rounded mt-[2%] text-xs	font-medium ${
                          item.student_status === 1 ? "bg-[#D2EAD5] text-[#00C81D] border-[#00C81D]" : "bg-[#ECD8D8] text-[#DC362E] border-[#DC362E]"
                        } `}
                      >
                        <div className={`flex items-center mr-[4px] ${item.student_status === 1 && "hidden"}`}>
                          <img src={Info} />
                        </div>

                        <div>{item.student_status === 1 ? "Enrolled" : "Enrollment Pending"}</div>
                      </div>
                      <div className="flex justify-end mt-[15%] ">
                        <button
                          className="teepee-button w-[40%]"
                          // onClick={() =>
                          //   item.student_status !== 2 && handleViewClass(item)
                          // }
                          onClick={() => handleViewClass(item)}
                        >
                          View Class
                        </button>
                      </div>
                    </div>
                    {/* Old Classroom Flow */}
                    {/* <div className="flex justify-between">
                      <div
                        className="class_name w-fit max-w-[50%]"
                        id="class_name"
                      >
                        <div className="block  whitespace-nowrap overflow-hidden text-ellipsis">
                          {item.classroom_name}
                        </div>
                        <div className="flex justify-between">
                          <div className="teepee-divider w-[100%] mt-[auto]"></div>
                        </div>
                      </div>
                      <button
                        className="r-[20px] view_class"
                        value={item.classroom_id}
                        onClick={handleViewClass}
                      >
                        (View class)
                      </button>
                    </div> */}

                    {/* <div>
                      {item.quizzes.length > 0 ? (
                        <>
                          {item.quizzes.map((item, index) => (
                            <div
                              className="quiz-div  mb-[44px] bg-[#f7f2f9] rounded-[12px] mt-[24px]"
                              onClick={() => viewQuiz(item)}
                            >
                              <div className="inner-my-quizzes">
                                <div className="flex">
                                  <div className="quiz_circle_student bg-[#6750A4] rounded-full text-center flex mr-[16px]">
                                    <label className="text-white">
                                      {item.quiz_type === 1
                                        ? "F"
                                        : item.quiz_type === 2
                                        ? "M"
                                        : "E"}
                                    </label>
                                  </div>
                                  <div className="quiz-item">
                                    <div className="dashboard-my-quizzes-name text-with-dots">
                                      {item.quiz_title}
                                    </div>
                                    <div className="dashboard-my-quizzes-topic classroom_name_div">
                                      {item.subject_name}
                                    </div>
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div className="dashboard-my-quizzes-info ">
                                <div className="dashboard-my-quizzes-subject text-with-dots">
                                </div>
                                <div
                                  className="dashboard-my-quizzes-topic text-with-dots mt-[25px]"
                                  style={{ display: "flex" }}
                                >
                                  <div className="mr-[8px]">
                                    <img src={Quiz}></img>
                                  </div>
                                  <div className="width-full">
                                    {item.quiz_type === 2
                                      ? "Mini-mock exam"
                                      : item.quiz_type === 1
                                      ? "Formative"
                                      : "Exam-style"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="no-quiz-label mt-14 mb-10 text-center ">
                          Looks like quizzes are yet to arrive!
                        </div>
                      )}
                    </div> */}
                  </div>
                ))}
              </>
            ) : (
              <div className="no-quiz-label" style={{ display: isLoading ? "none" : "" }}>
                You have not joined any classes yet
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentClassroomsCard;
