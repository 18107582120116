import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import ShareClassCodeDialogue from "./create_classroom_dialogue/ShareClassCodeDialogue";
import appContext from "../../context/appContext";
import TeacherHomeTabs from "./TeacherHomeTabs";
import ClassroomDetailBanner from "./widgets/ClassroomDetailBanner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SubscriptionExpirationBanner from "./widgets/SubscriptionExpirationBanner";
import moment from "moment";
import BuyVoucherPopup from "../Subscription/BuyVoucherPopup";
import UpgradePlan from "../Subscription/UpgradePlan";

function TeacherClassroomHome() {
  const location = useLocation();
  const state = useContext(appContext);
  const search = window.location.search;
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const [classroomData, setClassroomData] = useState({});
  const [studentData, setStudentData] = useState();
  const [classroomAnalyticsData, setClassroomAnalyticsData] = useState();
  const [quizTypeAnalyticsSelection, setQuizTypeAnalyticsSelection] = useState(3);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [startDateFilterActual, setStartDateFilterActual] = useState(null);
  const [endDateFilterActual, setEndDateFilterActual] = useState(null);
  const [trialObject, setTrialObject] = useState();
  const [open2, setOpen2] = useState(false);
  const [typeFilter, setTypeFilter] = useState(3);
  const [open, setOpen] = useState(false);
  const [subjectData, setSubjectData] = useState();
  const [studentId, setStudentId] = useState(null);
  const [analyticsData, setAnalyticsData] = useState();
  const [individualAnalyticsData, setIndividualAnalyticsData] = useState();
  const [data2, setData2] = useState();
  const [classroomDataRetrieval, setClassroomDataRetrieval] = useState({});
  const [vouchersRate, setVouchersRate] = useState(5);
  const [OffIdicator, setOffIndicator] = useState(-1);
  const [sliderRangeText, setSliderRange] = useState(1);
  const [noOfStudent, setNoOfStudents] = useState(1);
  const [trialData, setTrialData] = useState({});
  const isStep = useRef(false);

  var class_id = params.get("id");

  const [selectedOption, setSelectedOption] = useState("1");

  // Function to handle the change in radio button selection
  function onValueChange(event) {
    // Updating the state with the selected radio button's value
    setSelectedOption(parseInt(event.target.value));
  }

  const removeStudent = (index) => {
    console.log("REMOVED CALLED");
    var array = [...studentData]; // make a separate copy of the array

    //var index = e.target.value;
    if (index !== -1) {
      array.splice(index, 1);
      setStudentData(array);
    }
  };

  const changeStudentEnrollmentStatus = (index, status) => {
    getClassData();
  };

  const handleCloseDateDialogue = () => {
    setOpen(false);
  };
  const handleCloseWithConfirm = () => {
    getAnalytics(quizTypeAnalyticsSelection, startDateFilterActual, endDateFilterActual);
    setOpen(false);
  };

  const handleOpenDateDialogue = () => {
    console.log("called");
    setOpen(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
    // setVouchersRate(5);
    // setOffIndicator(-1);
    // setSliderRange(1);
    // setNoOfStudents(1);
    // isStep.current = false;
  };

  const onChangeFilter = (e) => {
    console.log("OnChange Called", e.target.name, e.target.value);
    if (e.target.name === "typeFilter") {
      setTypeFilter(e.target.value);
    } else if (e.target.name === "quiztypeFilter") {
      setQuizTypeAnalyticsSelection(e.target.value);
      getAnalytics(e.target.value, startDateFilterActual, endDateFilterActual);
    }
  };

  const onChangeStartDateFilter = (e) => {
    setStartDateFilter(e);
    const year = e.$y;
    const month = e.$M + 1;
    const day = e.$D;
    const startDate = `${year}-${month}-${day}`;
    setStartDateFilterActual(startDate);
  };
  const onChangeEndDateFilter = (e) => {
    setEndDateFilter(e);
    const year2 = e.$y;
    const month2 = e.$M + 1;
    const day2 = e.$D;
    const endDate = `${year2}-${month2}-${day2}`;
    setEndDateFilterActual(endDate);
  };

  useEffect(() => {
    getClassData();
    getAnalytics(3, null, null);
    getClassEngagementAnalytics(class_id);
    if (studentId === null) {
      getClassStudentAnalytics(class_id);
      setIndividualAnalyticsData(null);
    } else {
      getClassIndividualStudentAnalytics(class_id, studentId);
      setAnalyticsData(null);
    }
  }, [class_id, studentId]);

  const getAnalytics = (quizType, startDate, endDate) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/fetch_class_analytics", {
        method: "POST",
        classroom_id: class_id,
        start_date: startDate,
        end_date: endDate,
        quiz_type: quizType,
      })
      .then((res) => {
        setClassroomAnalyticsData(res.data);
        console.log("ANALYTICS DATA: ", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getClassEngagementAnalytics = (class_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_class_analytics", {
        method: "POST",
        classroom_id: class_id,
      })
      .then((res) => {
        setClassroomDataRetrieval(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getClassStudentAnalytics = (classroom_id) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/get_class_students_analytics",
        {
          classroom_id: classroom_id,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setAnalyticsData(response.data.class_students_analytics);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClassData = () => {
    if (localStorage.getItem("teacher_table_state") === null) {
      localStorage.setItem("teacher_table_state", 1);
    }
    localStorage.setItem("quizTabState", 1);
    const currentDate = moment();
    const futureDate = currentDate.add(1, "hours"); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/view_class", {
        method: "POST",
        class_id: class_id,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        trial_end_date: expiryDate,
      })
      .then((res) => {
        var data = res.data;
        setTrialObject(res.data.trial_data);
        console.log("now data will be", data);
        const currentTeacher = res.data.teacher_data.filter(
          (teacher) => teacher.email_address.toLowerCase() === localStorage.getItem("email_address").toLocaleLowerCase()
        );
        console.log("MY CURRENT TEACHER: ", currentTeacher.length);

        if (currentTeacher.length === 0 || currentTeacher[0].status === 0) {
          navigate("/");
          return;
        }
        if (res.data.state === 0) {
          const updatedData = {
            ...res.data,
            subject: {
              ...res.data.subject,
              ...res.data.trial_data,
            },
          };
          // Set the updated data to the state
          setSubjectData(updatedData);
          state.setSelectedSubjectForClass(res.data.subject.subject_name);
          state.setSelectedSubjectIdForClass(res.data.subject.subject_id);
          state.selectedSubjectObject.current = updatedData.subject;
          if (res.data && res.data.trial_data && res.data.trial_data.trial_period_tracking_id) {
            res.data.subject["trial_period_tracking_id"] = res.data.trial_data.trial_period_tracking_id;
          } else {
            console.error("trial_period_tracking_id not found in the response.");
          }
        } else {
          setSubjectData(res.data);
          state.setSelectedSubjectForClass(res.data.subject.subject_name);
          state.setSelectedSubjectIdForClass(res.data.subject.subject_id);
          startTrialPeriod(res.data.subject.subject_id, res.data.subject);
          // state.handleOpenTrialGetStartedPopup();
        }
        setClassroomData(data);
        setStudentData(res.data.student);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClassIndividualStudentAnalytics = (classroom_id, student_id) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/get_individual_student_class_analytics",
        {
          classroom_id: classroom_id,
          student_id: student_id,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setIndividualAnalyticsData(response.data.individual_student_analytics);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRequestedUserFlow = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && state.selectedSubjectIdForClass === 10) {
      return 0;
    } else {
      return 1;
    }
  };
  const startTrialPeriod = (subject_id, subject) => {
    const currentDate = moment();
    const futureDate = currentDate.add(1, "hours"); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/start_trial_period", {
        method: "POST",
        // user_feedback: user_feedback,
        subject_id: subject_id,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        trial_end_date: expiryDate,
        request_user: handleRequestedUserFlow(),
      })
      .then((response) => {
        console.log("response: ", response);
        // state.handleCloseTrialGetStartedPopup();
        state.handleOpenTrialAcknowledgePopup();
        setTrialData(response.data.trial_data);
        if (response.data && response.data.trial_data && response.data.trial_data.trial_period_tracking_id) {
          subject["trial_period_tracking_id"] = response.data.trial_data.trial_period_tracking_id;
        } else {
          console.error("trial_period_tracking_id not found in the response.");
        }
        Object.assign(subject, response.data.trial_data);
        state.selectedSubjectObject.current = subject;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = () => {
    window.location.reload();
    state.setOpenBuyVoucherPopUp(false);
    // setVouchersRate(5);
    // setOffIndicator(-1);
    // setSliderRange(1);
    // setNoOfStudents(1);
    // isStep.current = false;
  };
  console.log("SUBJECT DATA ", subjectData);
  // useEffect(() => {
  //   state.handleOpenTrialAcknowledgePopup();
  // }, []);
  return (
    <div>
      {subjectData?.subject && (
        <UpgradePlan
          open={state.openBuyVoucherPopup}
          handleClose={handleClose}
          subjectName={subjectData?.subject?.subject_name}
          subject={subjectData?.subject}
        />
      )}
      <ShareClassCodeDialogue
        handleCloseShareClassroomDialogue={state.handleClickCloseShareClassCodeDialogue}
        openShareClassroomDialogue={state.openShareClassroomDialogue}
        classroomData={classroomData ? classroomData : {}}
      />

      <div>
        <Sidebar />
      </div>
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        {trialObject !== undefined && Object.keys(trialObject).length && (
          <SubscriptionExpirationBanner setOpen={state.setOpenBuyVoucherPopUp} bannerObject={trialObject} subjectData={subjectData.subject} />
        )}

        {classroomData && classroomData.classroom_code && <ClassroomDetailBanner classroomData={classroomData} />}
        <div id="tabs" className="home pt-[28px] md:pl-[20px]">
          {classroomData && classroomData.classroom_code ? (
            <TeacherHomeTabs
              classroomData={classroomData}
              typeFilter={typeFilter}
              quizTypeAnalyticsSelection={quizTypeAnalyticsSelection}
              onChangeFilter={onChangeFilter}
              startDateFilter={startDateFilter}
              endDateFilter={endDateFilter}
              handleCloseDateDialogue={handleCloseDateDialogue}
              handleOpenDateDialogue={handleOpenDateDialogue}
              handleCloseWithConfirm={handleCloseWithConfirm}
              open={open}
              onChangeStartDateFilter={onChangeStartDateFilter}
              onChangeEndDateFilter={onChangeEndDateFilter}
              studentData={studentData}
              removeStudent={removeStudent}
              getClassData={getClassData}
              classroomAnalyticsData={classroomAnalyticsData}
              selectedOption={selectedOption}
              handleChange={onValueChange}
              bannerObject={trialObject}
              subjectData={subjectData}
              changeStudentEnrollmentStatus={changeStudentEnrollmentStatus}
              analyticsData={analyticsData ? analyticsData : individualAnalyticsData}
              setStudentId={setStudentId}
              studentId={studentId}
              data2={classroomDataRetrieval}
            />
          ) : (
            <div className="flex items-center justify-center mt-[15%]  w-full">
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeacherClassroomHome;
