import React from "react";
import Slider from "react-slick";
import { useState } from "react";
import Modal from "react-modal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useScreenSize from "../Componenets/useScreenSize";
import YouTube from "react-youtube";
import amplitude from "amplitude-js";

function DashboardVideosCard(props) {
  const screenSize = useScreenSize();
  console.log(screenSize);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const openModal = (videoLink) => {
    setSelectedVideo(videoLink);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedVideo(null);
  };
  var videoData = props.videoList;
  const settings = {
    variableWidth: true,
    swipeToSlide: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:
      screenSize.width > 315 && screenSize.width < 960
        ? 1
        : screenSize.width > 1000 && screenSize.width < 1200
        ? 1
        : screenSize.width > 1200 && screenSize.width < 1600
        ? 2
        : 3,
    slidesToScroll: 1,
  };
  const data = props.videoList;

  const opts = {
    height: "200",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const onPlayerStateChange = (event, videoLink) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      event.target.pauseVideo();
      setSelectedVideo(videoLink);
      setModalIsOpen(true);
    }
  };

  return (
    <>
      <div className="md:w-[95%] m-auto">
        <div className="">
          {videoData === null ||
            (videoData.length === 0 && (
              <div className="no-quiz-label text-center w-[100%]">
                No video content available.
              </div>
            ))}
          <Slider {...settings}>
            {data.map((d) => (
              <div
                key={d.name}
                className="bg-white sm:h-[200px] md:h-[300px] text-black rounded-xl !w-[105%]"
              >
                <div className="flex flex-col  justify-center sm:mt-2 ">
                  <div className="my-quiz-div" style={{ height: "auto" }}>
                    <div className="dashboard-my-quizzes-info !pl-[0] sm:mb-[30%] md:mb-0">
                      {
                        <YouTube
                          iframeClassName="rounded-xl"
                          videoId={d.video_links}
                          opts={opts}
                          onReady={_onReady}
                          onPlay={() => {
                            amplitude
                              .getInstance()
                              .setUserId(localStorage.getItem("email_address"));
                            amplitude.getInstance().logEvent("PlayVideo");
                          }}
                          onStateChange={(event) => {
                            onPlayerStateChange(event, d.video_links);
                          }}
                        />
                        //             <iframe
                        //               className=""
                        //               onClick={() => openModal(d.video_links)}
                        //               width="100%"
                        //               height="200"
                        //               src={`https://www.youtube.com/embed/${d.video_links}`}
                        //               title="YouTube video player"
                        //               frameborder="0"
                        //               allow="accelerometer;
                        // autoplay;
                        // clipboard-write;
                        // encrypted-media;
                        // gyroscope;
                        // picture-in-picture;
                        // web-share"
                        //               allowfullscreen
                        //             ></iframe>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <Modal
            className="md:ml-[320px] md:mt-[10%] md:mr-[10%]"
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Video Modal"
            // Add your modal styling here
          >
            {selectedVideo && (
              <iframe
                className="text-center"
                width="100%"
                height="500" // Adjust as needed
                src={`https://www.youtube.com/embed/${selectedVideo}`}
                title="YouTube video player"
                frameborder="0"
                allowfullscreen
              ></iframe>
            )}
            {/* <button onClick={closeModal}>Close</button> */}
          </Modal>
        </div>
      </div>

      {/* <div className="flex flex-col items-center">
        <div className="grid xlg:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2 mt-0  w-full">
          {videoData !== null &&
            videoData.length > 0 &&
            videoData.map((item) => (
              <div className="quiz-div" style={{ height: "auto" }}>
                <div className="dashboard-my-quizzes-info !pl-[0]">
                  {
                    <iframe
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${item.video_links}`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; 
            autoplay; 
            clipboard-write; 
            encrypted-media; 
            gyroscope; 
            picture-in-picture; 
            web-share"
                      allowfullscreen
                    ></iframe>
                  }
                </div>
              </div>
            ))}
        </div>
      </div> */}
    </>
  );
}

export default DashboardVideosCard;
