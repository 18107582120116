import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import appContext from "../../../context/appContext";
import DashboardLabel from "./DashboardLabel";
import UpdateLessonPlanButton from "./UpdateLessonPlanButton";
import ParticipationMetrics from "./ParticipationMetrics";
import AreasToFocusOn from "./AreasToFocusOn";
import StudentsNeedingAttention from "./StudentsNeedingAttention";
import CommonMisconceptionsTable from "./CommonMisconceptionsTable";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import ManageRetrievalDashboardTabBody from "./ManageRetrievalDashboardTabBody";
import ManageRetrievalTeacherAnalyticsTabBody from "./ManageRetrievalTeacherAnalyticsTabBody";
import WelcomeComponent from "./WelcomeComponent";
import LessonPlanCommingSoon from "./LessonPlanCommingSoon";

function TeacherRetrievalTabBody(props) {
  console.log("ANALYTICS DATAs: ", props.data);

  const [assignedQuizzes, setAssignedQuizzes] = useState([]);
  const [draftQuizzes, setDraftQuizzes] = useState([]);
  const [tabState, setTabState] = useState("1");
  const navigate = useNavigate();
  const state = useContext(appContext);
  const classroomData = props.classroomData;
  const handleClick = () => {
    navigate("/update-lesson-plans", {
      state: {
        classroomData: props.classroomData,
        selectedSubject: classroomData.subject,
        class_data: classroomData,
        subject_status_have_lesson: props.classroomData.subject.subject_status_have_lesson,
      },
    });
  };

  const handleClickPrior = () => {
    var stateData = {
      class_data: props.classroomData,
      subject_status_have_lesson: props.classroomData.subject.subject_status_have_lesson,
      selectedSubject: props.classroomData.subject,
    };
    navigate("/add-prior-plans", { state: stateData });
  };

  useEffect(() => {
    // Add any necessary API calls here
  }, [props.classroomData]);

  const data = [
    {
      label: "Dashboard",
      value: "1",
      body: <>{tabState === "1" && <ManageRetrievalDashboardTabBody data={props.data} />}</>,
    },
    {
      label: "Analytics",
      value: "2",
      body: (
        <>
          {tabState === "2" && (
            <ManageRetrievalTeacherAnalyticsTabBody
              data={props.analyticsData}
              setStudentId={props.setStudentId}
              studentId={props.studentId}
              classroomData={props.classroomData}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {props.classroomData.retrieval_data === false && <LessonPlanCommingSoon />}
      <div style={{ display: props.classroomData.retrieval_data === true ? "" : "none" }}>
        <div className="home !text-[#6750A4] sm:ml[5px] md:ml-[15px] underline teacher_navigation_text mt-[1.5vw] mb-2">
          <button className="" onClick={() => navigate(`/teacher-subject-home?id=${props.classroomData.subject.subject_id}`)}>
            {"Back"}
          </button>
        </div>
        {/* {props.classroomData.have_lesson === 1 && <DashboardLabel text="My Lesson Plans" />} */}
        {/* <UpdateLessonPlanButton handleClick={handleClick} assignedQuizzes={assignedQuizzes} draftQuizzes={draftQuizzes} /> */}
        <WelcomeComponent haveLesson={props.classroomData.have_lesson} onClickSchedule={handleClick} handleClickPrior={handleClickPrior} />
        <div>
          <Tabs value={tabState} className="w-[100%] ">
            <div>
              <center>
                <div className="flex float-right mt-[2%]">
                  <div className="ml-[10px]">
                    <TabsHeader
                      className="w-[100%] h-[58px] justify-center bg-[#6750A414]"
                      indicatorProps={{
                        className: "bg-[#6750A414] shadow-none !text-gray-900",
                      }}
                    >
                      {data.map(({ label, value, icon }) => (
                        <div
                          onClick={() => {
                            setTabState(value);
                          }}
                        >
                          <Tab id="ABC" key={value} value={value} icon={icon} className="w-[100%] mr-[20px] ">
                            <div>{label}</div>
                          </Tab>
                        </div>
                      ))}
                    </TabsHeader>
                  </div>
                </div>
              </center>
            </div>
            <div className="mt-[2%]">
              <TabsBody animate={{}}>
                {data.map(({ value, body }) => (
                  <TabPanel key={value} value={value} className="p-0">
                    {body}
                  </TabPanel>
                ))}
              </TabsBody>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default TeacherRetrievalTabBody;
