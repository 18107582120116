import React, { useEffect, useRef, useState } from "react";
import QuizCard from "./widget/QuizCard";
import axios from "axios";
import { useLocation } from "react-router-dom";

function RetrievalQuiz() {
  const [selectedQuiz, setSelectedQuiz] = useState({});
  const [quizType, setQuizType] = useState(0);
  const [quizOrLesson, setQuizOrLesson] = React.useState(-1);
  const [quizData, setQuizData] = useState([]);
  const [quizTotalMarks, setQuizTotalMarks] = useState();
  const quizScore = useRef(0);
  const [selectedQuizQuestionIterationIndex, setSelectedQuizQuestionIterationIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [attemptedQuiz, setAttemptedQuiz] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [nextQuiz, setNextQuiz] = useState([]);
  const [reviewedQuiz, setReviewedQuiz] = useState([]);
  const [responseId, setResponseId] = useState(-1);

  const nextQuiz_id = useRef();
  const selectedSubTopicId = useRef();
  const quizMarksReview = useRef(0);
  const isQuizSubmitted = useRef(false);
  const classId = useRef("");

  const location = useLocation();
  let { quiz } = location.state !== null ? location.state : "";
  let { class_id } = location.state !== null ? location.state : "";
  let { quizState } = location.state !== null ? location.state : "";

  const getQuiz = () => {
    setSelectedQuiz(quiz);
    const token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/student_get_quiz", {
        method: "POST",
        student_quiz_id: quiz.student_quiz_id,
      })
      .then((res) => {
        console.log("Quiz: ", res.data);
        setQuizType(1);
        setQuizOrLesson(0);
        handleQuizValue(res.data.quiz_question_list);
        handleQuizTotalMarks(quiz.quiz_marks);
        nextQuiz_id.current = quiz.student_quiz_id;
        handleNewQuizState(res.data.progress[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleQuizValue = (quiz) => {
    setQuizData(quiz);
  };
  const handleQuizTotalMarks = (quizTotalMarks) => {
    setQuizTotalMarks(quizTotalMarks);
  };
  const handleNewQuizState = (quizProgressData) => {
    if (quizProgressData.status === 1) {
      quizScore.current = quizProgressData.obtained_score;
      setSelectedAnswerIndex(-1);
      setQuizCompleted(true);
      setResponseId(quizProgressData.retrieval_quiz_response_id);
      return;
    }
    if (quizProgressData) {
      quizScore.current = quizProgressData.obtained_score;
      setSelectedQuizQuestionIterationIndex(quizProgressData.question_iteration_index);
      setSelectedAnswerIndex(-1);
      setIsSubmitted(false);
      setQuizCompleted(false);
      setAttemptedQuiz([]);
      setResponseId(quizProgressData.retrieval_quiz_response_id);
    } else {
      quizScore.current = 0;
      setSelectedQuizQuestionIterationIndex(0);
      setSelectedAnswerIndex(-1);
      setIsSubmitted(false);
      setQuizCompleted(false);
      setAttemptedQuiz([]);
    }
  };

  const handleQuestionSubmission = (questionResponse) => {
    console.log("STUDENT QUIZ ID: ", selectedQuiz);
    const token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/submit_question_response", {
        method: "POST",
        classroom_id: selectedQuiz.classroom_id,
        student_quiz_id: selectedQuiz.student_quiz_id,
        total_score: quizTotalMarks,
        obtained_score: quizScore.current,
        question_response: questionResponse,
        quiz_response_id: responseId,
        last_question: selectedQuizQuestionIterationIndex === quizData.length - 1 ? true : false,
      })
      .then((res) => {
        setResponseId(res.data.quiz_response_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleIterationIndex = (IterationType) => {
    setShowButton(false);
    let index = selectedQuizQuestionIterationIndex;
    if (selectedQuizQuestionIterationIndex < quizData.length - 1) {
      setSelectedQuizQuestionIterationIndex(++index);

      if (quizType === 2) {
        if (IterationType === -1) {
          index = index - 2;
          setSelectedQuizQuestionIterationIndex(index);
        }
        const selectedAnswerIndex = quizData[index].answers.findIndex(
          (answer) => parseInt(quizData[index].student_selected_option) === answer.quiz_options_id
        );
        setSelectedAnswerIndex(selectedAnswerIndex);
        setIsSubmitted(true);
      } else {
        setSelectedAnswerIndex(-1);
        setIsSubmitted(false);
      }
    } else {
      if (IterationType === -1) {
        index = index - 1;
        setSelectedQuizQuestionIterationIndex(index);
      } else {
        setQuizCompleted(true);
        // if (quizType === 1) {
        //   submitQuiz(attemptedQuizLocal);
        // }
      }
    }
  };
  const handleQuizMarks = (questionMarks, quizType) => {
    if (quizType === 2) {
      quizMarksReview.current = quizMarksReview.current + questionMarks;
    }
    let score = quizScore.current;
    quizScore.current = score + questionMarks;
    // setQuizScore();
  };
  const submitQuiz = (attemptedQuizLocal) => {
    isQuizSubmitted.current = true;
    console.log("QUIZ DATA IS: ", selectedQuiz);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/submit_quiz", {
        method: "POST",
        classroom_id: class_id ? class_id : classId.current,
        student_quiz_id: selectedQuiz.student_quiz_id,
        total_score: selectedQuiz.quiz_marks,
        obtained_score: quizScore.current,
        question_response: attemptedQuizLocal,

        // lesson_key: lesson.lesson_key,
      })
      .then((res) => {
        console.log("reponse: ", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSelectedAnswer = (index) => {
    setSelectedAnswerIndex(index);
  };
  const handleSubmittedStatus = (submitState) => {
    if (selectedQuizQuestionIterationIndex <= quizData.length - 1) {
      if (quizType === 1) {
        handleQuizMarks(quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score);
      }
    }
    setIsSubmitted(submitState);
    let attemptedQuizLocal = attemptedQuiz;
    if (quizType === 1) {
      var obj = {
        student_has_question_id: quizData[selectedQuizQuestionIterationIndex].student_has_question_id,
        student_selected_option: quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_id,
        score: quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score,
        feedback:
          quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 0
            ? quizData[selectedQuizQuestionIterationIndex].quiz_incorrect_feedback
            : quizData[selectedQuizQuestionIterationIndex].quiz_correct_feedback,

        recall_status: quizData[selectedQuizQuestionIterationIndex].recall_status,
      };
      attemptedQuizLocal = attemptedQuizLocal.concat(obj);
      setAttemptedQuiz(attemptedQuizLocal);
    }
    if (quizState === 1) {
      handleQuestionSubmission(obj);
    }
  };
  const handleClickQuizReview = (quiz) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/review_quiz", {
        method: "POST",
        class_id: class_id,
        quiz_response_id: quiz.quiz_response_id,
      })
      .then((res) => {
        console.log("returning DATA Is: ", res.data);

        console.log("DATA IS: ", res.data.quiz_question_list);
        setQuizOrLesson(0);
        setReviewedQuiz(res.data.quiz_question_list);
        handleQuizValue(res.data.quiz_question_list);
        handleQuizTotalMarks(quiz.quiz_marks);
        handleQuizReviewedState(res.data.quiz_question_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleQuizReviewedState = (selectedQuiz) => {
    console.log("selected quiz here", selectedQuiz);
    quizMarksReview.current = 0;
    const selectedAnswerIndex = selectedQuiz[0]?.answers.findIndex(
      (answer) => parseInt(selectedQuiz[0]?.student_selected_option) === answer.quiz_options_id
    );
    setSelectedQuizQuestionIterationIndex(0);
    setSelectedAnswerIndex(selectedAnswerIndex);
    setIsSubmitted(true);
    setQuizCompleted(false);
    setAttemptedQuiz([]);
    setQuizType(2);
    for (let index = 0; index < selectedQuiz.length; index++) {
      const question = selectedQuiz[index];
      for (let index = 0; index < question.answers.length; index++) {
        const answer = question.answers[index];
        if (parseInt(question.student_selected_option) === answer.quiz_options_id) {
          handleQuizMarks(answer.quiz_options_score, 2);
          break;
        }
      }
    }
  };
  useEffect(() => {
    if (quizState === 1) {
      getQuiz();
    } else {
      handleClickQuizReview(quiz);
    }
  }, []);

  console.log("ddsad", quizData);
  const selectedTopicId = useRef();
  return (
    <div className="h-[100%] mt-2">
      {quizData.length > 0 && (
        <QuizCard
          selectedTopicId={selectedTopicId}
          selectedSubTopicId={selectedSubTopicId}
          quizOrLesson={quizOrLesson}
          quizData={quizData}
          selectedQuizQuestionIterationIndex={selectedQuizQuestionIterationIndex}
          currentIndex={currentIndex}
          handleIterationIndex={handleIterationIndex}
          selectedAnswerIndex={selectedAnswerIndex}
          handleSelectedAnswer={handleSelectedAnswer}
          isSubmitted={isSubmitted}
          handleSubmittedStatus={handleSubmittedStatus}
          quizCompleted={quizCompleted}
          quizScore={quizScore.current}
          handleQuizMarks={handleQuizMarks}
          quizTotalMarks={quizTotalMarks}
          submitQuiz={submitQuiz}
          quizType={quizType}
          quizMarksReview={quizMarksReview}
          nextQuiz_id={nextQuiz_id}
          nextQuiz={nextQuiz}
          handleClickQuizReview={handleClickQuizReview}
          setNextQuiz={setNextQuiz}
          isQuizSubmitted={isQuizSubmitted}
        />
      )}
    </div>
  );
}

export default RetrievalQuiz;
