import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { useNavigate } from "react-router-dom";
import plusIcon from "../images/plus.svg";
import logoutIcon from "../images/ic_logout.svg";
import profileIcon from "../images/ic_profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import teepeeLogo from "../images/avatar4.png";
import { useWindowSize } from "@uidotdev/usehooks";
import CreateClassroomDialogue from "./Teacher/create_classroom_dialogue/CreateClassroomDialogue";
import appContext from "../context/appContext";
import StudentJoinClassroomDialogue from "./Student/Dialogues/StudentJoinClassroomDialogue";
import { styled as styleds } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import axios from "axios";
import GetStartedPopUp from "./Teacher/TrialPeriod/GetStartedPopUp";
import TrialPeriodAcknowledgmentPopup from "./Teacher/TrialPeriod/TrialPeriodAcknowledgmentPopup";
import shared_icon from "../images/shared.png";
import JoinClassCodeDialogue from "./Teacher/create_classroom_dialogue/JoinClassCodeDialogue";
const Nav = styled.div`
  background: white;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #f7f2f9;
  width: 330px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 0ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
`;

const Sidebar = ({ item_id }) => {
  const navigate = useNavigate();
  const size = useWindowSize();
  // console.log("width is: ", size);
  // const testSidebar = useRef(() => {
  //   if (size.width >= 960) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });
  // console.log("dsadasdasd", testSidebar.current);
  const userType = localStorage.getItem("user_type");

  const [sidebar, setSidebar] = useState(true);
  const [openCreateClassroomDialogue, setOpenCreateClassroomDialogue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openJoinClassroomDialogue, setOpenJoinClassroomDialogue] = useState(false);

  const handleClickCloseJoinClassroomDialogue = () => {
    setOpenJoinClassroomDialogue(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickOpen = () => {
    state.handleClickOpenCreateClassroomDialogue(true);
  };
  const state = useContext(appContext);

  // const handleClose = () => {
  //   state.setCreateClassroomDialogue(false);
  // };

  const showSidebar = (e) => {
    e.preventDefault();
    setSidebar(!sidebar);
  };
  const handleClick = () => {
    navigate("/create-quiz");
  };

  function onLogoutClick() {
    var url = localStorage.getItem("user_type") === "3" ? "/student_logout" : "/logout";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
        method: "POST",
      })
      .then((response) => {
        console.log(response);
        localStorage.removeItem("access_token");
        localStorage.removeItem("student_name");
        localStorage.removeItem("user_type");
        localStorage.removeItem("email_address");
        localStorage.removeItem("quiz_taker");
        localStorage.removeItem("quiz_type");
        localStorage.removeItem("class_code");
        localStorage.removeItem("student");
        localStorage.removeItem("experience");
        window.location.replace("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    // console.log("height and width of the screen", size);
    if (size.width >= 960) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  }, [size]);

  var backgroundColor = "transparent";
  // useEffect(() => {
  //   console.log("CALLED");
  // }, [sidebar.current]);

  const HtmlTooltip = styleds(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top" />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#49454F",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      borderRadius: "8px",
      opacity: "0.8",
      backgroundColor: "#FFF",
      padding: "12px 16px",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 330,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <>
      <CreateClassroomDialogue
        openCreateClassroomDialogue={state.createClassroomDialogue}
        handleClicksetOpenCreateClassroomDialogue={state.handleClickOpenCreateClassroomDialogue}
        handleClickCloseCreateClassroomDialogue={state.handleCloseCreateClassroomDialogue}
      />
      <StudentJoinClassroomDialogue
        openJoinClassroomDialogue={state.openJoinClassroomDialogue}
        handleClickOpenJoinClassroomDialogue={state.handleClickOpenJoinClassroomDialogue}
        handleClickCloseJoinClassroomDialogue={state.handleClickCloseJoinClassroomDialogue}
      />
      <GetStartedPopUp
        openTrialGetStartedPopup={state.openTrialGetStartedPopup}
        handleOpenTrialGetStartedPopup={state.handleOpenTrialGetStartedPopup}
        handleCloseTrialGetStartedPopup={state.handleCloseTrialGetStartedPopup}
      />
      <TrialPeriodAcknowledgmentPopup
        openTrialAcknowledgePopup={state.openTrialAcknowledgePopup}
        handleOpenTrialAcknowledgePopup={state.handleOpenTrialAcknowledgePopup}
        handleCloseTrialAcknowledgePopup={state.handleCloseTrialAcknowledgePopup}
      />
      <div style={{ zIndex: "1000" }} className="sticky-div">
        <div className="w-full h-[60px] border-b border-[#E4E1E7] pr-[15px] sticky-div  flex">
          <div className="w-[30%] absolute top-[-10px]">
            <div className="flex w-full items-center " style={{ display: userType !== "3" && "none" }}>
              <img
                alt=""
                className="teepee-sidebar-icon mt-[20px] self-center ml-[10%] "
                style={{ cursor: "pointer" }}
                src={teepeeLogo}
                onClick={() => {
                  window.location.replace("/");
                }}
              />
            </div>
            <NavIcon to="#" className={userType === "3" ? "hidden" : "md:!hidden pb-[10px] ml-[10px]"}>
              <FaIcons.FaBars onClick={(e) => showSidebar(e)} className={userType === "3" ? "hidden" : " pb-[10px] ml-[10px]"} />
            </NavIcon>
          </div>
          <div className="w-[100%]">
            <Dropdown className="float-right">
              <Dropdown.Toggle id="profile-dropdown" variant="secondary">
                <img alt="" src={profileIcon}></img>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="light" className="mt-4">
                <Dropdown.Item>{localStorage.getItem("email_address")}</Dropdown.Item>

                <Dropdown.Divider className={`${userType === "3" ? "hidden" : "block"}`} />
                <Dropdown.Item style={{ display: userType === "3" && "none" }}>
                  <div
                    className="w-full flex text-center mr-2 "
                    onClick={() => {
                      setOpenJoinClassroomDialogue(true);
                    }}
                  >
                    <img alt="" className="mr-2 mt-1 h-[16px] w-[16px]" src={shared_icon}></img>
                    Join Shared Class
                  </div>
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item onClick={onLogoutClick}>
                  <div className="w-full flex">
                    <img alt="" className="mr-[5px]" src={logoutIcon}></img>
                    Logout
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <JoinClassCodeDialogue
              openJoinClassroomDialogue={openJoinClassroomDialogue}
              handleClickCloseJoinClassroomDialogue={handleClickCloseJoinClassroomDialogue}
              setOpenJoinClassroomDialogue={setOpenJoinClassroomDialogue}
            />
            <div
              className="float-right md:mr-[60px] h-[100%]"
              style={{
                display: userType === "1" || userType === "2" ? "block" : "none",
              }}
            >
              <div onClick={handleClickOpen} className="mt-[10px]">
                <button style={{ width: "100%" }} type="button" className="create-class-icon" onClick={handleClickOpen}>
                  <div className="inline-flex">
                    <img className="create-quiz-button-icon" src={plusIcon} alt="" />
                    <ClickAwayListener onClickAway={handleClose}>
                      <div>
                        <HtmlTooltip
                          enterTouchDelay={0}
                          leaveTouchDelay={10}
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                          title={
                            <React.Fragment>
                              <div className="p-[12px]">
                                <Typography color="inherit" className="heading-create-class-tooltip pb-[5px]">
                                  🎓 Explore Classroom Dynamics!
                                </Typography>
                                <Typography className="sub-heading-create-class-tooltip pl-[25px] pb-[5px]">
                                  By creating a classroom, you can:
                                </Typography>
                                <div className="flex items-start first-button-create-class-tooltip pb-[5px] ">
                                  <li></li>
                                  <div className="flex-1 pl-0 ">👥 Easily enroll students and manage your class.</div>
                                </div>

                                <div className="flex items-start first-button-create-class-tooltip pb-[5px]">
                                  <li></li>
                                  <div className="flex-1 pl-0">📊 Gain valuable insights into each student's progress.</div>
                                </div>

                                <div className="flex items-start first-button-create-class-tooltip pb-[5px]">
                                  <li></li>
                                  <div className="flex-1 pl-0">📝 Assign and schedule quizzes with a few clicks.</div>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <span>Create Class</span>
                        </HtmlTooltip>
                      </div>
                    </ClickAwayListener>
                  </div>
                </button>
              </div>
            </div>
            {/* Join classroom for Student */}
            <div
              className="float-right md:mr-[60px] h-[100%]"
              style={{
                display: userType === "3" ? "block" : "none",
              }}
            >
              <div onClick={state.handleClickOpenJoinClassroomDialogue} className="mt-[10px]">
                <button style={{ width: "100%" }} type="button" className="create-class-icon" onClick={state.handleClickOpenJoinClassroomDialogue}>
                  <div className="inline-flex">
                    <img className="create-quiz-button-icon" src={plusIcon} alt="" />
                    <span>Join Class</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <IconContext.Provider value={{ color: "#F7F2F9" }}>
          <SidebarNav className="overflow-auto hide-scrollbar" id="sidebarNav" sidebar={userType === "3" ? false : sidebar}>
            <SidebarWrap>
              <div className="sm:block md:hidden ">
                <NavIcon to="#">
                  <AiIcons.AiOutlineClose onClick={showSidebar} color="black" />
                </NavIcon>
              </div>

              <div className="flex w-full justify-center items-center">
                <img
                  alt=""
                  className="teepee-sidebar-icon mt-[20px] self-center mb-[50px]"
                  style={{ cursor: "pointer" }}
                  src={teepeeLogo}
                  onClick={() => {
                    window.location.replace("/");
                  }}
                />
              </div>

              {/* <button
                style={{ width: "100%" }}
                type="button"
                className="create_quiz_button"
                onClick={handleClick}
              >
                <div className="inline-flex">
                  <img
                    className="create-quiz-button-icon"
                    src={plusIcon}
                    alt=""
                  />{" "}
                  <span>Create Quiz</span>
                </div>
              </button> */}

              {SidebarData.map((item, index) => {
                let expandSubNav = false;
                if (item.id === "users_feedback" && (item_id === "users_feedback" || item_id === "evaluation_feedback")) {
                  item.backgroundColor = "#e8def8";
                  expandSubNav = true;
                } else if (item_id === item.id) {
                  item.backgroundColor = "#e8def8";
                  expandSubNav = true;
                } else {
                  item.backgroundColor = "transparent";
                }

                if (
                  userType === "1" &&
                  item.id !== "user_option" &&
                  item.id !== "users_feedback" &&
                  item.id !== "view_subject" &&
                  item.id !== "view_subject_detail" &&
                  item.id !== "evaluation_feedback" &&
                  item.id !== "edit_predefined_questions" &&
                  item.id !== "tracking_analytics" &&
                  item.id !== "view_onboarding_subject" &&
                  item.id !== "dashboard" &&
                  item.id !== "subject-analysis" &&
                  item.id !== "configure_subscription" &&
                  item.id !== "promo_code" &&
                  item.id !== "custom_notification" &&
                  item.id !== "pricing"
                )
                  return <SubMenu item_id={item_id} expandSubNav={expandSubNav} backgroundColor={backgroundColor} item={item} key={index} />;
                if (userType === "2" && item.id !== "subscription_teacher")
                  return <SubMenu item_id={item_id} expandSubNav={expandSubNav} item={item} key={index} />;
                else return null;
              })}
              <hr className="solid"></hr>
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </div>
    </>
  );
};

export default Sidebar;
