import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import axios from "axios";
import StudentClassroomsCard from "./widget/StudentClassroomsCard";
import ClassroomIcon from "../../images/Classroom-icon-student.png";

function StudentDashbaord() {
  return (
    <div>
      <Sidebar />

      <div className=" sm:ml-0 md:ml-[350px] mb-[40px] mt-[2%]">
        <div className="flex pl-[2%]">
          <div className="flex items-center">
            <img src={ClassroomIcon} />
          </div>
          <div className="classroom_heading ml-[1%]">Classrooms</div>
        </div>
        <StudentClassroomsCard />
      </div>
    </div>
  );
}

export default StudentDashbaord;
