import React, { useState } from "react";

function ReplaceQuestionChapterDropdown(props) {
  const [chapters, setChapters] = useState(props.chapterList);

  const chapterList = chapters.map((chapter) => (
    <option key={chapter.chapter_id}>{chapter.chapter_name}</option>
  ));
  return (
    <div className="pt-[10px]">
      <select
        id="modules_option"
        onChange={props.handleOnChange}
        name="chapter"
        className=" teepee-select shadow-none leading-[30px] font-[300] px-4 w-[100%] h-[100%] bg-transparent"
      >
        <option value="Select topic" defaultValue={"Select topic"}>
          Select topic
        </option>
        {chapterList}
      </select>
    </div>
  );
}

export default ReplaceQuestionChapterDropdown;
