import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import BackArrow from "../images/icon.svg";
import Dialog from "@mui/material/Dialog";
import magicianImage from "../images/magician.gif";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import amplitude from "amplitude-js";
import { type } from "@testing-library/user-event/dist/type";
import IncDecCounter from "./IncDecCounter";
import AnswerAccordian from "./AnswerAccordian";
import CustomizedAccordions from "./Accordian";
import QuizRawResponseAccordian from "./QuizRawResponseAccordian";
import Setting from "../images/settings.png";
import { Checkbox, DialogTitle, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import infoLogo from "../images/info.png";
import ReplaceLogo from "../images/replace.png";
import ReplaceDialogue from "./widgets/ReplaceDialogue";
import { SxProps } from "@mui/material/styles";
import ClassroomDetailBanner from "./Teacher/widgets/ClassroomDetailBanner";
import TeacherNavigationText from "./Teacher/widgets/TeacherNavigationText";
import ConfirmationDialogue from "./Teacher/confirmationdialogue/ConfirmationDialogue";
import AcknowledgementDialogue from "./Teacher/confirmationdialogue/AcknowledgementDialogue";
import QuizSetting from "./widgets/QuizSetting";
import appContext from "../context/appContext";
import SubscriptionExpirationBanner from "./Teacher/widgets/SubscriptionExpirationBanner";
import Emergency from "../images/emergency.svg";
import DashboardLabel from "./Teacher/widgets/DashboardLabel";

function AddDetails() {
  const location = useLocation();

  const parentRef = useRef();
  const grandchildRef = useRef();
  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };

  const navigate = useNavigate();
  const state = useContext(appContext);
  const [response_data, setResponseData] = useState([]);
  const [mock_exam, setMockExam] = useState(null);
  const [quizDuration, setQuizDuration] = useState(1);
  const [isAnswerRubric, setIsAnswerRubric] = useState();
  const [createQuiz, setcreateQuiz] = useState({
    quizName: "",
    quizSubject: location.state ? location.state.classroomData && location.state.classroomData.subject.subject_name : "",
    hasExamStylePrompt: 0,
    hasMockExams: 0,
    formativeQuizTypeStatus: 0,
    folder_id: location.state !== null && location.state.folder_id !== null ? location.state.folder_id : null,
  });
  const [subjects, setSubjects] = useState([]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const question_answers = useRef([]);
  const quizTypesList = useRef([]);
  //const quizType = useRef();
  const sub_topic_ids_csv = useRef([]);
  const creation_tokens = useRef(0);
  const quiz_generation_ids_list = useRef([]);
  const sub_topic_name = useRef([]);
  const showReplacedQuestion = useRef(0);
  const replacedQuestionIndex = useRef();
  const blackListedQuestionArray = useRef([]);
  const blackListedQuestionsToAvoid = useRef([]);
  const checkedSubTopicList = useRef([]);
  const hasExamStylePrompt = useRef(0);
  const formativeQuizTypeStatus = useRef(0);
  const hasMockExams = useRef(0);
  const selectedSubject = useRef(null);

  let { data } = location.state !== null ? location.state : "";

  const selectedTopicId = useRef(-1);
  //const numberOfQuestions = useRef(2);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const numberOfQuestionsTest = useRef(0);
  const userType = localStorage.getItem("user_type");
  const url = location.state ? location.state.url && location.state.url : null;
  const selectedQuizTypeId = useRef(formativeQuizTypeStatus.current ? 1 : hasMockExams ? 2 : hasExamStylePrompt ? 3 : null);
  const lockFields = useRef(false);
  const aiEnabled = useRef(false);
  const [open, setOpen] = React.useState(false);
  const [openViewLogsDialog, setOpenViewLogsDialog] = React.useState(false);
  const [errorOnShareQuiz, setErrorOnShareQuiz] = React.useState(false);
  const [requiredSso, setRequiredSso] = useState(false);
  const [selectedQuizType, setSelectedQuizType] = useState(formativeQuizTypeStatus.current ? 1 : hasExamStylePrompt ? 3 : hasMockExams ? 2 : null);
  const [openReplaceDialogue, setOpenReplaceDialogue] = React.useState(false);
  const [replaceQuestionIndex, setReplaceQuestionIndex] = useState();
  const [checkedTopicList, setCheckedTopicList] = useState([]);
  const [checkedModuleList, setCheckedModuleList] = useState([]);
  const replacedQuestionIndexStatus = useRef([]);
  const [quizDurationSetting, setQuizDurationSetting] = useState(false);
  const [quizTiming, setQuizTiming] = useState(0);
  const [graceDurationToggle, setGraceDurationToggle] = useState(false);
  const [graceDuration, setGraeDuration] = useState(0);
  const [autoSubmissionToggle, setAutoSubmitionToggle] = useState(false);
  const [hideAnswerToggle, setHideAnswerToggle] = useState(false);
  const [configureToggle, setConfigureToggle] = useState(false);
  const [shuffleQuestionToggle, setShuffleQuestionToggle] = useState(false);
  const [AIQuestionToggle, setAIQuestionToggle] = useState(false);
  const [quizSetting, setQuizSetting] = useState({});
  const [updateQuizSettingPopUp, setUpdateQuizSettingPopUp] = useState(false);
  const [selectedPaperCategory, setSelectedPaperCategory] = useState(null);
  const [quizName, setQuizName] = useState("");
  const handleOpenUpdateQuizSetting = () => {
    setUpdateQuizSettingPopUp(true);
  };
  const handleCloseUpdateQuizSetting = () => {
    setUpdateQuizSettingPopUp(false);
  };
  const handleCloseUpdateQuizSettingWithConfirm = () => {
    changeIndividualQuizSettings();
  };

  //Confirmation Dialogues state
  const [buttonEvent, setButtonEvent] = useState();
  const [quizSettingDialogue, setQuizSettingsDialogue] = useState(false);
  const [openShareQuizConfirmationDialogue, setOpenShareQuizConfirmationDialogue] = useState(false);
  const [openShareQuizAcknowledgementDialogue, setOpenShareQuizAcknowledgementDialogue] = useState(false);
  const [openDeleteQuizConfirmationDialogue, setOpenDeleteQuizConfirmationDialogue] = useState(false);

  const [openDeleteAcknowledgementDialgoue, setOpenDeleteAcknowledgementDialgoue] = useState();

  const handleOpenShareQuizConfirmationDialogue = (e) => {
    setButtonEvent(e);
    setOpenShareQuizConfirmationDialogue(true);
  };
  const handleCloseShareQuizConfirmationDialogueWithCancel = () => {
    setOpenShareQuizConfirmationDialogue(false);
  };
  const handleCloseShareQuizConfirmationDialogueWithConfirm = () => {
    handleClickOnShareWithStudentBtn(buttonEvent);
    setOpenShareQuizConfirmationDialogue(false);
  };
  const handleOpenShareQuizAcknowledgementDialogue = () => {
    setOpenShareQuizAcknowledgementDialogue(true);
  };
  const handleCloseShareQuizAcknowledgementDialogue = () => {
    setOpenShareQuizAcknowledgementDialogue(false);
    handleOnClick();
  };
  const handleOpenDeleteQuizConfirmationDialgue = () => {
    setOpenDeleteQuizConfirmationDialogue(true);
  };
  const handleCloseDeleteQuizConfirmationDialgueWithCancel = () => {
    setOpenDeleteQuizConfirmationDialogue(false);
  };
  const handleCloseDeleteQuizConfirmationDialgueWithConfirm = () => {
    deleteDraftQuiz();
    setOpenDeleteQuizConfirmationDialogue(false);
  };
  function removeHtmlTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in the string
    return str.replace(/<p>/g, "");
  }

  const handleOpenDeleteAcknowledgementDialgoue = () => {
    setOpenDeleteAcknowledgementDialgoue(true);
  };
  const handleCloseDeleteAcknowledgementDialogue = () => {
    setOpenDeleteAcknowledgementDialgoue(false);
    window.location = location.state.url;
  };

  const handleClickOpen = (index) => {
    setReplaceQuestionIndex(index);
    setOpenReplaceDialogue(true);
  };

  const handleClose = () => {
    setOpenReplaceDialogue(false);
  };

  const handleChange = (val) => {
    setRequiredSso(val);
  };
  var id = params.get("id");
  const [quizDetails, setQuizDetails] = useState({
    chapter_id: -1,
    topic_id: [],
    subTopic: [],
    numOfQuestions: 0,
    quizType: formativeQuizTypeStatus.current ? 1 : hasMockExams ? 2 : hasExamStylePrompt ? 3 : 3,
  });

  const selectedContentCount = useRef(0);
  const [chapters, setChapters] = useState([]);
  const [papers, setPapers] = useState([]);
  var topic = [];
  const [topics, setTopics] = useState(topic);
  const [quizTypes, setQuizTypes] = useState([
    (formativeQuizTypeStatus.current === 1 || id !== null) && {
      quiz_type: 1,
      quiz_type_name: "Formative quiz",
    },
    (hasExamStylePrompt === 1 || id !== null) && {
      quiz_type: 3,
      quiz_type_name: "Exam style quiz (Beta)",
    },
    (hasMockExams === 1 || id !== null) && {
      quiz_type: 2,
      quiz_type_name: "Mini mock (Beta)",
    },
  ]);

  const [subTopics, setSubTopics] = useState([]);
  const [isSubmittingShare, setIsSubmittingShare] = useState(false);
  var remainingSubtopics;

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id != null && id.length > 0) {
      lockFields.current = true;
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_details_for_review", {
          method: "POST",
          quiz_secret_key: id,
        })

        .then((response) => {
          console.log("response: ", response);
          // setQuizSetting(response.data[0]);
          if (response.data.quiz_duration === 0) {
            setQuizDurationSetting(false);
            setGraceDurationToggle(false);
            setAutoSubmitionToggle(false);
          } else {
            setQuizDurationSetting(true);
            setQuizTiming(response.data.quiz_duration / 60);
            if (response.data.quiz_grace_time !== 0) {
              setGraceDurationToggle(true);
              setGraeDuration(response.data.quiz_grace_time / 60);
            }
          }
          setHideAnswerToggle(response.data.quiz_instant_feedback_status !== 0 ? true : false);
          setShuffleQuestionToggle(response.data.shuffle_quiz_questions !== 0 ? true : false);
          setAutoSubmitionToggle(response.data.quiz_auto_submission !== 0 ? true : false);

          var question_answers_list = JSON.parse(response.data["question_answers"]);
          var topicNmae = response.data["topic_name"];
          setCheckedTopicList(topicNmae);
          var moduleName = response.data["module_name"];
          setCheckedModuleList(moduleName);
          setcreateQuiz({ ...createQuiz, quizName: JSON.parse(response.data["quiz"]).quiz_title });
          question_answers.current = question_answers_list;
          setResponseData(question_answers.current);
          // document.getElementById("number_of_questions").value=question_answers_list.length
          quizDetails.numOfQuestions = question_answers_list.length;
          setNumberOfQuestions(quizDetails.numOfQuestions);
          var quiz = JSON.parse(response.data["quiz"]);
          var sub_topics_list = JSON.parse(response.data["sub_topics_list"]);
          console.log("subtopics names are: ", response.data["sub_topic_name"]);
          // for (let index = 0; index < sub_topics_list.length; index++) {
          //   const element = sub_topics_list[index];
          //   sub_topic_name.current.push(element.sub_topic_name);
          // }
          sub_topic_name.current = response.data["sub_topic_name"];
          console.log("subtopics are: ", sub_topic_name.current);

          setSelectedQuizType(quiz.quiz_type);

          if (quiz.quiz_type === 2) {
            if (response.data["mock_exam"].length > 0) {
              var mock_exam = JSON.parse(response.data["mock_exam"]);
              setMockExam(mock_exam);
              setPapers(response.data.paper_type);
            }

            //setResponseData([]);
          }
          //e.target.name="chapter";
          //e.target.value="Sasdf";
          selectedQuizTypeId.current = quiz.quiz_type;
          var type;

          if (quiz.quiz_type === 1) {
            type = [{ quiz_type_id: 1, quiz_type_name: "Formative quiz" }];
            setQuizTypes(type);
          } else if (quiz.quiz_type === 2) {
            type = [{ quiz_type_id: 2, quiz_type_name: "mini-mocks (beta)" }];
            setQuizTypes(type);
          } else {
            type = [{ quiz_type_id: 3, quiz_type_name: "Exam style quiz (beta)" }];
            setQuizTypes(type);
          }

          var quizTypesList2 = [];
          for (var index = 0; index < type.length; index++) {
            if (type[index] !== false) {
              quizTypesList2.push(<option id={type[index].quiz_type_id}>{type[index].quiz_type_name}</option>);
            }
          }

          quizTypesList.current = quizTypesList2;

          setChapters([]);
          var chapter = [{ chapter_name: quiz.chapter_name }];
          setChapters(chapter);

          var topic = [
            {
              topic_id: quiz.topic_id,
              topic_name: quiz.topic_name,
              chapter_id: -1,
            },
          ];
          setTopics(topic);
          setSubTopics(sub_topics_list);
          setIsAnswerRubric(response.data["show_answer_rubric"]);
          //chapterList=chapter;
          //data = createQuiz;

          console.log(question_answers_list);
          //question_answers.current = question_answers_list;
          //setResponseData(question_answers.current);

          //question_answers.current = response.data['question_answers'];
          //setResponseData(question_answers.current)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, quizDetails]);

  useEffect(() => {
    if (id === null) {
      if (location.state?.classroomData) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("ICAddMyQuizDetail");
      } else {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("OCAddMyQuizDetail");
      }
    }
  }, []);

  const handleClickOnShareWithStudentBtn = (e) => {
    if (id !== null && location.state) {
      console.log("THIS IS THE CASE: ");
    }
    if (id != null && id.length > 0 && location.state) {
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_status_of_quiz", {
          method: "POST",
          quiz_id: response_data[0].quiz_id,
        })
        .then((response) => {
          console.log("RESPONSE IS: ", response.data);
          localStorage.setItem("teacher_table_state", 1);
          handleOpenShareQuizAcknowledgementDialogue();
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    }
    // e.preventDefault();
    if (id != null && id.length > 0) {
      navigate("/share?id=" + id);
      return;
    }
    setIsSubmittingShare(true);

    let dt;

    if (selectedQuizType === 1 || selectedQuizType === 3) {
      dt = {
        quiz_name: data.quizName,
        topic_id: selectedTopicId.current,
        question_answers: response_data,
        sub_topic_ids_csv: sub_topic_ids_csv.current,
        creation_tokens_count: creation_tokens.current,
        quiz_generation_ids_list: quiz_generation_ids_list.current,
        sso_required: false,
        quiz_type: selectedQuizTypeId.current,
        folder_id: data.folder_id,
        class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
        quiz_status: e,
        quiz_instant_feedback_status: hideAnswerToggle,
        quiz_duration: quizTiming,
        quiz_grace_time: graceDuration,
        quiz_auto_submission: autoSubmissionToggle,
        shuffle_quiz_questions: shuffleQuestionToggle,
        subject_id: location.state.subjectData ? location.state.subjectData.subject_id : null,
        account_status: location.state.bannerObject
          ? handleAccontStatus(location.state.bannerObject.account_status, location.state.bannerObject)
          : null,
      };
    } else if (selectedQuizType === 2) {
      dt = {
        quiz_name: data.quizName,
        // subject_id: localStorage.getItem("selected_subject_id"),
        mock_exam_id: mock_exam.mock_exam_id,
        sso_required: false,
        quiz_type: selectedQuizTypeId.current,
        quiz_generation_ids_list: quiz_generation_ids_list.current,
        quiz_duration: quizDuration,
        folder_id: data.folder_id,
        class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
        quiz_status: e,
        quiz_instant_feedback_status: hideAnswerToggle,
        quiz_duration: quizTiming === 0 ? mock_exam.mock_exam_duration : quizTiming,
        quiz_grace_time: graceDuration,
        quiz_auto_submission: autoSubmissionToggle,
        shuffle_quiz_questions: shuffleQuestionToggle,
        subject_id: location.state.subjectData
          ? location.state.subjectData.subject_id
          : localStorage.getItem("selected_subject_id")
          ? localStorage.getItem("selected_subject_id")
          : null,
        account_status: location.state.bannerObject
          ? handleAccontStatus(location.state.bannerObject.account_status, location.state.bannerObject)
          : null,
      };
    }

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/save_quiz", {
        method: "POST",
        body: dt,
      })

      .then((response) => {
        console.log(response);
        var id = response.data["secret_key"];
        setIsSubmittingShare(false);

        if (location.state.classroomData) {
          amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
          amplitude.getInstance().logEvent("ICSharetheQuizWithStudents", "User has pressed the share quiz button for quiz key: {" + id + "}");
        } else {
          amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
          amplitude.getInstance().logEvent("OCSharetheQuizWithStudents", "User has pressed the share quiz button for quiz key: {" + id + "}");
        }

        if (location.state.classroomData) {
          if (e === "2") {
            handleOpenShareQuizAcknowledgementDialogue();
            return;
          }
          handleOpenShareQuizAcknowledgementDialogue();
          return;
        }
        navigate("/share?id=" + id);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmittingShare(false);
        setErrorOnShareQuiz(true);
      });
  };
  // const handleGenerateQuizBtnClick = () => {
  //   const sub_topic_id_list = [1, 2]

  //   generateQuiz(sub_topic_id_list);
  // };

  const removeQuestion = (index) => {
    var indexArray = [...replacedQuestionIndexStatus.current];
    if (index !== -1) {
      indexArray.splice(index, 1);
      replacedQuestionIndexStatus.current = indexArray;
    }
    var array = [...response_data]; // make a separate copy of the array

    //var index = e.target.value;
    if (index !== -1) {
      array.splice(index, 1);
      setResponseData(array);
    }
  };
  const replaceQuestion = (index, newQuestion) => {
    if (replacedQuestionIndexStatus.current.length === 0) {
      for (let index = 0; index < response_data.length; index++) {
        replacedQuestionIndexStatus.current.push(0);
      }
    }
    replacedQuestionIndexStatus.current[index] = 1;
    var array = [...response_data];
    if (index !== -1) {
      array[index] = newQuestion;
      setResponseData(array);
    }
  };

  const handleClickScroll = () => {
    const element = document.getElementById("quiz-section");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (response_data.length > 0) {
      handleClickScroll();
    }
  }, [response_data]);

  const { handleSubmit, formState } = useForm();

  const { isSubmitting } = formState;

  const AccountStatus = {
    TRIAL_ACTIVE: 1,
    TRIAL_EXPIRED: 2,
    SUBSCRIPTION_ACTIVE: 3,
    SUBSCRIPTION_EXPIRED: 4,
    SPECIAL_CONDITION: 5,
  };
  const handleAccontStatus = (status, object) => {
    if (AccountStatus.TRIAL_ACTIVE === status) {
      return 1;
    } else if (AccountStatus.SUBSCRIPTION_ACTIVE === status) {
      return 3;
    } else if (AccountStatus.SPECIAL_CONDITION === status) {
      if (object.subscription_days_left) {
        return 3;
      } else {
        return 1;
      }
    }
  };

  function submitForm(data) {
    if (location.state.classroomData) {
      console.log("inside the classroom");
    } else {
      console.log("outside the classroom");
    }
    question_answers.current = [];
    sub_topic_ids_csv.current = "";
    creation_tokens.current = "";
    quiz_generation_ids_list.current = [];
    setResponseData(question_answers.current);
    setMockExam(null);
    handleClickScroll();
    return new Promise((resolve) => {
      if (selectedSubject.exam_paper_quiz_status === 0) {
        if (quizDetails.subTopic.length > 0 && quizDetails.numOfQuestions > 0 && quizDetails.numOfQuestions <= 10) {
        } else {
          if (selectedQuizType === 1 || selectedQuizType === 3) {
            var msg = "Please enter all details";
            if (quizDetails.numOfQuestions > 10) {
              msg = "Number of questions must be less than or equal to 10 ";
            }
            alert(msg);
            resolve();
            return;
          } else if (selectedQuizType === 2) {
            if (selectedPaperCategory === null) {
              alert("Please enter all details");
              resolve();
              return;
            }
          }
        }
      }

      //const sub_topic_id_list = [1, 2]
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      lockFields.current = true;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/generate_quiz", {
          method: "POST",
          subject_id: localStorage.getItem("selected_subject_id"),
          sub_topic_id_list: quizDetails.subTopic,
          number_of_questions: quizDetails.numOfQuestions,
          quiz_type: quizDetails.quizType,
          question_ids: blackListedQuestionArray.current,
          quiz_status: location.state.classroomData ? 1 : 0,
          exam_paper_quiz_status: selectedSubject.exam_paper_quiz_status,
          category: selectedQuizType === 2 && selectedPaperCategory[0].paper_id,
          account_status: location.state.bannerObject
            ? handleAccontStatus(location.state.bannerObject.account_status, location.state.bannerObject)
            : null,
        })

        .then((response) => {
          lockFields.current = true;
          console.log(response);
          if (location.state.classroomData) {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude
              .getInstance()
              .logEvent(
                "ICGenerate Quiz [" + (quizDetails.quizType === 2 ? "mini-mocks (beta)]" : quizDetails.quizType === 3 ? "Exam Style]" : "Formative]")
              );
          } else {
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude
              .getInstance()
              .logEvent(
                "OCGenerateQuiz [" + (quizDetails.quizType === 2 ? "mini-mocks (beta)]" : quizDetails.quizType === 3 ? "Exam Style]" : "Formative]")
              );
          }

          if (selectedQuizType === 2) {
            var mock_exam = JSON.parse(response.data["mock_exam"]);
            setMockExam(mock_exam);
            setQuizDuration(mock_exam.mock_exam_duration);
            setResponseData([]);
          } else if (selectedQuizType === 1 || selectedQuizType === 3) {
            var question_answers_list = JSON.parse(response.data["question_answers"]);

            var sub_topics = response.data["sub_topic_ids_csv"];
            console.log(question_answers_list);
            question_answers.current = question_answers_list;
            blackListedQuestionArray.current = question_answers.current;
            console.log("BLACK LISTED QUESTION ADD QUIZ: ", blackListedQuestionArray.current);
            blackListedQuestionsToAvoid.current = blackListedQuestionArray.current;
            sub_topic_ids_csv.current = sub_topics;
            creation_tokens.current = response.data["creation_tokens"];
            quiz_generation_ids_list.current = JSON.parse(response.data["quiz_generation_ids_list"]);
            // sub_topic_name.current = response.data["sub_topic_name"];
            // console.log("SUBTOPIC NAMES ARE: ", sub_topic_name.current);

            setResponseData(question_answers.current);
          }

          //quizType.current=quizDetails.quizType;

          //question_answers.current = response.data['question_answers'];
          //setResponseData(question_answers.current)

          resolve();
          handleClickScroll();
          // lockFields.current = false;
        })
        .catch((error) => {
          resolve();
          lockFields.current = false;
          console.log(error);
          if (error.code === "ERR_BAD_RESPONSE") {
            setOpen(true);
          }
        });
    });
  }

  // const onIncDecClick = ((value) => {
  //   setQuizDuration(value);
  // });

  const handleCloseWithCancel = () => {
    setOpen(false);
    document.getElementById("sub_topics_container").style.borderColor = "#474747";
  };

  const handleCloseWithCancelShareError = () => {
    setErrorOnShareQuiz(false);
  };

  const getListOfChapter = () => {
    let mounted = true;
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_chapters",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          subject_id: location.state.subjectData.subject_id,
          selected_quiz_type: selectedQuizType,
        }),
      })
      .then((res) => {
        if (mounted) {
          setChapters(res.data);
        }
      });

    return () => (mounted = false);
  };

  useEffect(() => {
    var quizTypesList2 = [];
    for (var index = 0; index < quizTypes.length; index++) {
      if (quizTypes[index] !== false) {
        quizTypesList2.push(<option id={quizTypes[index].quiz_type_id}>{quizTypes[index].quiz_type_name}</option>);
      }
    }
    quizTypesList.current = quizTypesList2;
    // quizTypes.map((quizType) => (
    //   quizType!==false) ? (
    //   ) : null
    // );

    if (id === null || id.length === 0) getListOfChapter();
  }, []);

  // useEffect(() => {
  //   // var quizTypesList2=[];
  //   // for(var index=0; index<quizTypes.length; index++)
  //   // {
  //   //     if(quizTypes[index]!==false)
  //   //     {
  //   //         quizTypesList2.push(<option id={quizTypes[index].quiz_type_id}>{quizTypes[index].quiz_type_name}</option>)
  //   //     }
  //   // }
  //   // quizTypesList.current = quizTypesList2;
  // }, [quizTypes]);

  var chapterList;

  if (selectedQuizType === 1) {
    chapterList = chapters.map((chapter) => <option>{chapter.chapter_name}</option>);
  } else {
    chapterList = chapters.map((chapter) => (
      <MenuItem key={chapter.chapter_id} value={chapter.chapter_name}>
        <Checkbox checked={checkedModuleList.indexOf(chapter.chapter_name) > -1} />
        <ListItemText primary={chapter.chapter_name} />
      </MenuItem>
    ));
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const paperList = papers !== null && papers.map((paper) => <option>{paper.paper_name}</option>);

  const topicList = topics.map((topic) => (
    <MenuItem key={topic.topic_id} value={topic.topic_name}>
      <Checkbox checked={checkedTopicList.indexOf(topic.topic_name) > -1} />
      <ListItemText primary={topic.topic_name} />
    </MenuItem>
  ));

  useEffect(() => {
    setCheckedTopicList([]);
    setSubTopics([]);
    getPaperCategories(localStorage.getItem("selected_subject_id"));
  }, []);

  const getPaperCategories = (subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_paper_category_for_quiz", {
        method: "POST",
        subject_id: subject_id,
      })

      .then((response) => {
        console.log("RESPONSE IS: ", response);
        setPapers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (checkedSubTopicList.current.length > 0) {
      var subTopicCheckBox = document.getElementsByName("subTopicCheckBox");
      for (var index = 0; index < checkedSubTopicList.current.length; index++) {
        for (var index2 = 0; index2 < subTopicCheckBox.length; index2++) {
          if (checkedSubTopicList.current[index] === subTopicCheckBox[index2].defaultValue) {
            subTopicCheckBox[index2].checked = true;
          }
        }
      }
    }
    checkedSubTopicList.current = [];
  }, [subTopics]);

  const handleQuizSetting = () => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.headers.post["authorization"] = token;
    const url =
      quizDetails.quizType === 1
        ? "/formative_get_quiz_settings_in_class"
        : quizDetails.quizType === 2
        ? "/minimock_get_quiz_settings_in_class"
        : "/exam_get_quiz_settings_in_class";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
        method: "POST",
        class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
      })

      .then((response) => {
        console.log(response);
        // setQuizSetting(response.data[0]);
        if (response.data.length === 0) {
          setQuizDurationSetting(false);
          setGraceDurationToggle(false);
          setAutoSubmitionToggle(false);
          setQuizTiming(0);
          setGraeDuration(0);
          setHideAnswerToggle(false);
          setShuffleQuestionToggle(false);
          return;
        }
        if (response.data[0].quiz_time_duration === 0) {
          setQuizDurationSetting(false);
          setGraceDurationToggle(false);
          setAutoSubmitionToggle(false);
        } else {
          setQuizDurationSetting(true);
          setQuizTiming(response.data[0].quiz_time_duration);
          if (response.data[0].quiz_grace_time !== 0) {
            setGraceDurationToggle(true);
            setGraeDuration(response.data[0].quiz_grace_time);
          }
        }
        setHideAnswerToggle(response.data[0].quiz_instant_feedback_status !== 0 ? true : false);
        setShuffleQuestionToggle(response.data[0].shuffle_quiz_questions !== 0 ? true : false);
        setAutoSubmitionToggle(response.data[0].quiz_auto_submission !== 0 ? true : false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChange = (e) => {
    replacedQuestionIndexStatus.current = [];
    //setQuizDetails({ ...quizDetails, [e.target.name]: e.target.value });
    console.log("Checked");

    if (e.target.name === "paper") {
      var paperCategory = papers.filter((paper) => paper.paper_name === e.target.value);

      setSelectedPaperCategory(paperCategory);
    }
    if (e.target.name === "numOfQuestions") {
      const re = /^[0-9\b]+$/;

      // if value is not blank, then test the regex

      if (e.target.value === "" || re.test(e.target.value)) {
        quizDetails.numOfQuestions = e.target.value;
        setQuizDetails({ ...quizDetails, [e.target.name]: e.target.value });

        return;
      }
    }

    if (e.target.name === "quiz_type") {
      var selectedQuiz;
      if (e.target.value === "Formative quiz") {
        selectedQuiz = 1;
      } else if (e.target.value === "Exam style quiz (Beta)") {
        selectedQuiz = 3;
      }
      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + "/get_chapters",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          data: JSON.stringify({
            subject_id: localStorage.getItem("selected_subject_id"),
            selected_quiz_type: selectedQuiz,
          }),
        })
        .then((res) => {
          setChapters(res.data);
        });
      quizDetails.subTopic = [];
      setTopics([]);
      setCheckedTopicList([]);
      setSubTopics([]);
      setNumberOfQuestions([]);
      setCheckedTopicList([]);
      setCheckedModuleList([]);
      quizDetails.numOfQuestions = 0;
      question_answers.current = [];
      sub_topic_ids_csv.current = "";
      creation_tokens.current = "";
      quiz_generation_ids_list.current = [];
      setResponseData(question_answers.current);
      setMockExam(null);

      var quizTypesList2 = [];
      for (var index = 0; index < quizTypes.length; index++) {
        if (quizTypes[index] !== false) {
          quizTypesList2.push(quizTypes[index]);
        }
      }

      quizDetails.quizType = quizTypesList2[e.target.selectedIndex].quiz_type;
      selectedQuizTypeId.current = quizDetails.quizType;
      setSelectedQuizType(quizDetails.quizType);
      handleQuizSetting();
      return;
    }

    if (e.target.name === "chapter") {
      if (selectedQuizType === 1) {
        setCheckedTopicList([]);
        setNumberOfQuestions(0);
        setSubTopics([]);
        quizDetails.subTopic = [];
        quizDetails.topic_id = [];
        const selectedChapter = chapters.filter((chapter) => chapter.chapter_name === e.target.value);
        if (selectedChapter.length === 0) {
          return;
        }
        aiEnabled.current = selectedChapter[0].ai_enabled_formative;
        setTopics(topic);
        console.log("Selected Component :", selectedChapter[0].chapter_id);
        axios
          .request({
            method: "POST",
            url: process.env.REACT_APP_REST_API_BASE_URL + "/get_topics",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            data: JSON.stringify({
              chapterId: selectedChapter[0].chapter_id,
              chapterName: selectedChapter[0].chapter_name,
              selected_quiz_type: selectedQuizType,
            }),
          })
          .then((res) => {
            console.log(res.data);

            topic = topic.concat(res.data);
            console.log(topic);
            setTopics(topic);
            // setSubTopics([]);
            // quizDetails.subTopic = [];
            // selectedContentCount.current = 0;
          });
        return;
      } else {
        var selectedChapterName = e.target.value[e.target.value.length - 1];
        const {
          target: { value },
        } = e;
        setCheckedModuleList(typeof value === "string" ? value.split(",") : value);

        const selectedChapterIds = chapters.filter((chapter) => value.includes(chapter.chapter_name)).map((chapter) => chapter.chapter_id);

        //Filtering the topics on the basis of chapter selection
        var remainingTopics;
        remainingTopics = topics.filter((topic) => selectedChapterIds.includes(topic.chapter_id));
        setTopics(remainingTopics);
        const selectedChapter = chapters.filter((chapter) => chapter.chapter_name === selectedChapterName);
        if (selectedChapter !== undefined && selectedChapter.length > 0) {
          aiEnabled.current = selectedChapter[0].ai_enabled;
        }

        var selectedChapterId;
        if (selectedChapter.length > 0) {
          selectedChapterId = selectedChapter[0].chapter_id;
        }

        if (selectedChapterIds.length < quizDetails.chapter_id.length) {
          var topicNames = topics.filter((topic) => selectedChapterIds.includes(topic.chapter_id)).map((topic) => topic.topic_name);
          setCheckedTopicList(checkedTopicList.filter((check) => topicNames.includes(check)));

          //Filter the subtopics on the basis of remaining topics.
          var remainingTopicsId = [];
          for (let index = 0; index < remainingTopics.length; index++) {
            const element = remainingTopics[index];
            remainingTopicsId.push(element.topic_id);
          }

          console.log("REMAINING TOPICS ARE: ", remainingTopicsId);
          console.log("SELECTED TOPICS ARE: ", quizDetails.topic_id);
          quizDetails.topic_id = quizDetails.topic_id.filter((selectedTopic) => remainingTopicsId.includes(selectedTopic));
          var remainingSubTopics = subTopics.filter((subTopic) => remainingTopicsId.includes(subTopic.topic_id));
          setSubTopics(remainingSubTopics);

          var remainingSubTopicsId = [];
          for (let index = 0; index < remainingSubTopics.length; index++) {
            const element = remainingSubTopics[index];
            remainingSubTopicsId.push(element.sub_topic_id);
          }

          console.log("SUBTOPICS IN THE QUIZ DETAILS ARE BEFORE FILTERING: ", quizDetails.subTopic);

          quizDetails.subTopic = quizDetails.subTopic.filter((selectedSubTopic) => remainingSubTopicsId.includes(parseInt(selectedSubTopic)));

          // var filteredSelectiveSubtopicArray = [];
          // for (let index = 0; index < quizDetails.subTopic.length; index++) {
          //   const selectedSubtopic = quizDetails.subTopic[index];
          //   for (
          //     let index2 = 0;
          //     index2 < remainingSubTopicsId.length;
          //     index2++
          //   ) {
          //     const remainingSubTopics = remainingSubTopicsId[index2];
          //     if (parseInt(selectedSubtopic) === remainingSubTopics) {
          //       filteredSelectiveSubtopicArray.push();
          //     }
          //   }
          // }
          // console.log(
          //   "FILTERED SELECTED SUBTOPIC ARRAY: ",
          //   filteredSelectiveSubtopicArray
          // );
          var subTopicCheckBox = document.getElementsByName("subTopicCheckBox");
          checkedSubTopicList.current = [];
          //keeping checked subtopic ids and marking all checkboxes unchecked
          for (let index2 = 0; index2 < subTopicCheckBox.length; index2++) {
            if (subTopicCheckBox[index2].checked) {
              checkedSubTopicList.current.push(subTopicCheckBox[index2].defaultValue);
            }
            subTopicCheckBox[index2].checked = false;
          }
          console.log("REMAINING SUBTOPIC ID: ", remainingSubTopicsId);
          console.log("SELECTED SUBTOPICS ARE: ", quizDetails.subTopic);
          // console.log("REMAING SUBTOPIC IDS ARE: ", remainingSubTopicsId);
          // console.log("BEFOR EDITING: ", quizDetails.subTopic);
          // var selectedSubtopics = quizDetails.subTopic;
          // console.log("SELECTED SUBTOPICS ARE: ", selectedSubtopics);
          // // console.log(
          // //   "FILTERING PROCESS FOR SUBTOPICS: ",
          // //   quizDetails.subTopic.filter(
          // //     (subTopic) => remainingSubTopicsId.includes(subTopic)
          // //   )

          // // );
          // for (let index = 0; index < remainingSubTopicsId.length; index++) {
          //   const element = remainingSubTopicsId[index].toString();
          //   element

          // }

          console.log("REMAINING SUBTOPICS ARE: ", remainingSubtopics);
          console.log("SELECT SUBTOPICS ARE: ", quizDetails.subTopic);

          console.log("Unchecked");
          quizDetails.chapter_id = selectedChapterIds;
          // quizDetails.topic_id = [];
          // quizDetails.subTopic = [];
        } else {
          quizDetails.chapter_id = selectedChapterIds;
          if (checkedModuleList.length === 0) {
            quizDetails.topic_id = [];
            quizDetails.subTopic = [];
          }

          axios
            .request({
              method: "POST",
              url: process.env.REACT_APP_REST_API_BASE_URL + "/get_topics",
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },
              data: JSON.stringify({
                chapterId: selectedChapter[0].chapter_id,
                chapterName: selectedChapter[0].chapter_name,
              }),
            })
            .then((res) => {
              console.log(res.data);
              const topic = res.data;
              setTopics(remainingTopics);
              // setSubTopics([]);
              // quizDetails.subTopic = [];
              // selectedContentCount.current = 0;
              setTopics(topics.concat(topic));
            });
        }
      }
    }

    if (e.target.name === "topic") {
      // setNumberOfQuestions(0);
      var selectedTopicName = e.target.value[e.target.value.length - 1];

      const {
        target: { value },
      } = e;
      const {
        target: { key },
      } = e;
      setCheckedTopicList(typeof value === "string" ? value.split(",") : value);
      console.log("CHECKED TOPIC LIST ARE: ", checkedTopicList);

      const selectedTopicIds = topics.filter((topic) => value.includes(topic.topic_name)).map((topic) => topic.topic_id);

      console.log("SUB TOPICS ARE: ", subTopics);
      var remainingSubtopics = [];
      remainingSubtopics = subTopics.filter((subTopic) => selectedTopicIds.includes(subTopic.topic_id));
      setSubTopics(remainingSubtopics);
      console.log("remaining Subtopics are: ", remainingSubtopics);
      console.log("SELECTED SUBTOPICS: ", quizDetails.subTopic);

      const selectedTopic = topics.filter((topic) => topic.topic_name === selectedTopicName);

      var selectedTopicIdL;

      if (selectedTopic.length > 0) {
        selectedTopicId.current = selectedTopic[0].topic_id;
      }

      if (selectedTopicIds.length < quizDetails.topic_id.length) {
        var remainingSubTopicsId = [];
        for (let index = 0; index < remainingSubtopics.length; index++) {
          const element = remainingSubtopics[index];
          remainingSubTopicsId.push(element.sub_topic_id);
        }

        var subTopicCheckBox = document.getElementsByName("subTopicCheckBox");
        checkedSubTopicList.current = [];
        //keeping checked subtopic ids and marking all checkboxes unchecked
        for (let index2 = 0; index2 < subTopicCheckBox.length; index2++) {
          if (subTopicCheckBox[index2].checked) {
            checkedSubTopicList.current.push(subTopicCheckBox[index2].defaultValue);
          }
          subTopicCheckBox[index2].checked = false;
        }

        remainingSubTopicsId = remainingSubTopicsId.toString();
        remainingSubTopicsId = remainingSubTopicsId.split(",");

        quizDetails.subTopic = quizDetails.subTopic.filter((subTopic) => remainingSubTopicsId.includes(subTopic));

        setSubTopics(remainingSubtopics);

        quizDetails.topic_id = selectedTopicIds;
        if (quizDetails.topic_id.length === 0) {
          quizDetails.numOfQuestions = 0;
          setNumberOfQuestions(0);
        }
      } else {
        quizDetails.topic_id = selectedTopicIds;
        if (quizDetails.topic_id.length > 0) {
          axios
            .request({
              method: "POST",
              url: process.env.REACT_APP_REST_API_BASE_URL + "/get_sub_topics",
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },
              data: JSON.stringify({
                topic_id: selectedTopicId.current,
                selected_quiz_type: selectedQuizType,
              }),
            })
            .then((res) => {
              console.log(res.data.sub_topic_list);
              const subtopic = res.data.sub_topic_list;
              setSubTopics(remainingSubtopics);
              setSubTopics(subTopics.concat(JSON.parse(subtopic)));
            });
        }
      }
    }

    if (e.target.name === "subTopicCheckBox") {
      if (selectedSubject.exam_paper_quiz_status === 1) {
        var sub_topics_container = document.getElementById("sub_topics_container");
        var chks = sub_topics_container.getElementsByTagName("input");
        for (var i = 0; i < chks.length; i++) {
          chks[i].onclick = function () {
            for (var i = 0; i < chks.length; i++) {
              if (chks[i] != this && this.checked) {
                chks[i].checked = false;
              }
            }
          };
        }
      }
      if (e.target.checked === true) {
        if (selectedContentCount.current + parseInt(e.target.alt) > 9000) {
          e.target.checked = false;
          setOpen(true);
          document.getElementById("sub_topics_container").style.borderColor = "red";
          return;
        }

        selectedContentCount.current += parseInt(e.target.alt);
        if (selectedSubject.exam_paper_quiz_status === 1) {
          quizDetails.subTopic[0] = e.target.value;
        } else {
          quizDetails.subTopic.push(e.target.value);
        }
        //var numberOfQuestions = 0
        //for(index = 0; index<subTopics.length;index++){
        //  numberOfQuestions+=1;
        // }

        var subTopicObj = null;

        if (selectedSubject.exam_paper_quiz_status === 1) {
          var subTopic = quizDetails.subTopic;
          subTopic = subTopics.filter((suptopic) => suptopic.sub_topic_id === parseInt(subTopic));
          subTopicObj = subTopic[0];

          if (!aiEnabled.current) {
            setNumberOfQuestions(parseInt(subTopicObj.number_of_predefined_questions));
            if (subTopicObj.number_of_predefined_questions <= 0) {
              quizDetails.numOfQuestions = 0;
            } else {
              if (quizDetails.subTopic.length === 1) {
                quizDetails.numOfQuestions = 1;
              }
            }
          } else {
            if (quizDetails.subTopic.length === 1) {
              quizDetails.numOfQuestions = 1;
            }
          }
          return;
        } else {
          for (index = 0; index < subTopics.length; index++) {
            if (subTopics[index].sub_topic_id === parseInt(e.target.value)) {
              subTopicObj = subTopics[index];
              break;
            }
          }
        }

        if (!aiEnabled.current) {
          setNumberOfQuestions(parseInt(numberOfQuestions + subTopicObj.number_of_predefined_questions));
          if (numberOfQuestions + subTopicObj.number_of_predefined_questions <= 0) {
            quizDetails.numOfQuestions = 0;
          } else {
            if (quizDetails.subTopic.length === 1) {
              quizDetails.numOfQuestions = 1;
            }
          }
        } else {
          setNumberOfQuestions(10);
          if (quizDetails.subTopic.length === 1) {
            quizDetails.numOfQuestions = 1;
          }
        }
      } else {
        var subTopicIndex = quizDetails.subTopic.indexOf(e.target.value);
        selectedContentCount.current -= parseInt(e.target.alt);

        subTopicObj = null;

        for (index = 0; index < subTopics.length; index++) {
          if (subTopics[index].sub_topic_id === parseInt(e.target.value)) {
            subTopicObj = subTopics[index];
            break;
          }
        }
        if (selectedSubject.exam_paper_quiz_status !== 1) {
          if (!aiEnabled.current) {
            setNumberOfQuestions(numberOfQuestions - subTopicObj.number_of_predefined_questions);
            if (numberOfQuestions - subTopicObj.number_of_predefined_questions <= 0) {
              quizDetails.numOfQuestions = 0;
            } else {
              if (quizDetails.subTopic.length === 1) {
                quizDetails.numOfQuestions = 1;
              }
            }
          } else {
            if (quizDetails.subTopic.length === 1) {
              quizDetails.numOfQuestions = 1;
            }
          }
        } else {
          setNumberOfQuestions(0);
          quizDetails.numOfQuestions = 0;
        }
        if (selectedSubject.exam_paper_quiz_status !== 1) {
          if (subTopicIndex !== -1) {
            quizDetails.subTopic.splice(subTopicIndex, 1);
          }
        }
        if (quizDetails.subTopic.length === 0) {
          setNumberOfQuestions(0);
        }
      }
      console.log("Characters count of selected sub-topic is: " + selectedContentCount.current);
      return;
    }
  };

  function refreshSubTopicsList(topicId) {
    quizDetails.topic_id = topicId;
    quizDetails.subTopic = [];
    setSubTopics([]);
    selectedTopicId.current = topicId;
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_sub_topics",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          topic_id: quizDetails.topic_id,
        }),
      })
      .then((res) => {
        // console.log("subtopic list", res.data.sub_topic_list);
        if (selectedQuizType === 1) {
          setSubTopics(JSON.parse(res.data.sub_topic_list));
        } else {
          console.log(res.data.sub_topic_list);
          const subtopic = res.data.sub_topic_list;
          setSubTopics(remainingSubtopics);
          setSubTopics(subTopics.concat(JSON.parse(subtopic)));
        }
      });
  }

  const subTopicList = subTopics.map((subTopic, index) => (
    <li key={index}>
      <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        {id !== null && (
          <input
            id={index}
            type="checkbox"
            checked="true"
            disabled={lockFields.current}
            name="subTopicCheckBox"
            alt=""
            onChange={onChange}
            value={subTopic.sub_topic_id}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
        )}
        {id === null && (
          <input
            id={index}
            type="checkbox"
            disabled={lockFields.current}
            name="subTopicCheckBox"
            alt=""
            onChange={onChange}
            value={subTopic.sub_topic_id}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
        )}

        <label htmlFor={index} className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-900">
          {subTopic.sub_topic_name}
        </label>
      </div>
    </li>
  ));

  const handleRawResponseClick = (secret_key) => {
    setOpenViewLogsDialog(true);
  };
  const handleViewLogDialogCloseWithCancel = () => {
    setOpenViewLogsDialog(false);
  };

  const handleOnClick = () => {
    if (location.state) {
      if (location.state.url) {
        console.log("BUTTON EVENT", buttonEvent);
        if (buttonEvent === "2") {
          localStorage.setItem("teacher_table_state", 2);
        } else {
          localStorage.setItem("teacher_table_state", 1);
        }
        window.location = location.state.url;
      }
    }
  };

  const deleteDraftQuiz = () => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_quiz_from_draft", {
        method: "POST",
        quiz_id: response_data[0].quiz_id,
      })
      .then((response) => {
        handleOpenDeleteAcknowledgementDialgoue();
        console.log("RESPONSE IS: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleQuizSettingDialogueOpen = () => {
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("Settings");
    setQuizSettingsDialogue(true);
  };
  const handleCloseQuizSettingDialogue = () => {
    setQuizSettingsDialogue(false);
    setConfigureToggle(false);
    if (id !== null) {
      handleOpenUpdateQuizSetting();
    }
  };

  const changeIndividualQuizSettings = () => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_individual_quiz_settings", {
        method: "POST",
        quiz_secret_key: id,
        class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
        quiz_type: selectedQuizType,
        quiz_time_duration: quizDurationSetting ? quizTiming : 0,
        quiz_grace_time: graceDurationToggle ? graceDuration : 0,
        quiz_auto_submission: autoSubmissionToggle,
        quiz_instant_feedback_status: hideAnswerToggle,
        shuffle_quiz_questions: shuffleQuestionToggle,
      })

      .then((response) => {
        console.log(response);
        setUpdateQuizSettingPopUp(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeSetting = (swictType, value) => {
    if (swictType === 1) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("QuizDuration");
      }
      setQuizDurationSetting(value);
      setQuizTiming(5);
      if (value === false) {
        setQuizTiming(0);
        setGraeDuration(0);
        setGraceDurationToggle(false);
        setAutoSubmitionToggle(false);
      }
      // changeIndividualQuizSettings()
    }
    if (swictType === 2) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("SubmissionGracePeriod");
      }
      setGraceDurationToggle(value);
      setGraeDuration(1);
      if (value === false) {
        setGraeDuration(0);
      }
      // changeIndividualQuizSettings()
    }
    if (swictType === 3) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("AutoSubmission");
      }
      setAutoSubmitionToggle(value);
      // changeIndividualQuizSettings()
    }
    if (swictType === 4) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("HideAnswers");
      }
      setHideAnswerToggle(value);
      // changeIndividualQuizSettings()
    }
    if (swictType === 5) {
      setConfigureToggle(value);
      if (value) {
        axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
        axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
        axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
        var token = "Bearer " + localStorage.getItem("access_token");
        axios.defaults.headers.post["authorization"] = token;

        axios
          .post(process.env.REACT_APP_REST_API_BASE_URL + "/set_class_quiz_setting", {
            method: "POST",
            class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
            quiz_type: selectedQuizType,
            quiz_time_duration: quizDurationSetting ? quizTiming : 0,
            quiz_grace_time: graceDurationToggle ? graceDuration : 0,
            quiz_auto_submission: autoSubmissionToggle,
            quiz_instant_feedback_status: hideAnswerToggle,
            shuffle_quiz_questions: shuffleQuestionToggle,
          })

          .then((response) => {
            console.log(response);
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("SaveDefaultSettings");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    if (swictType === 6) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("ShuffleQuestions");
      }
      setShuffleQuestionToggle(value);
    }
    if (swictType === 7) {
      if (value) {
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("ShuffleQuestions");
      }
      setAIQuestionToggle(value);
    }
  };
  const onIncDecClickQuizDuration = (value) => {
    console.log("value is: ", value);
    setQuizTiming(value);
  };
  const onIncDecClickQuizGraceDuration = (value) => {
    setGraeDuration(value);
  };

  useEffect(() => {
    // else{
    //   if(selectedQuizType === 1){
    //     url = "/formative_get_quiz_settings_in_class"
    //   }else if(selectedQuizType ===2){
    //     url = "/minimock_get_quiz_settings_in_class"
    //   }else if (selectedQuizType === 3){
    //     url = "/exam_get_quiz_settings_in_class"
    //   }
    // }
    if (id === null) {
      console.log("ONLY IN THE GENERATEION");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.headers.post["authorization"] = token;

      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/formative_get_quiz_settings_in_class", {
          method: "POST",
          class_id: location.state.classroomData ? location.state.classroomData.classroom_id : null,
        })

        .then((response) => {
          console.log(response);
          setQuizSetting(response.data[0]);
          if (response.data[0].quiz_time_duration === 0) {
            setQuizDurationSetting(false);
            setGraceDurationToggle(false);
            setAutoSubmitionToggle(false);
          } else {
            setQuizDurationSetting(true);
            setQuizTiming(response.data[0].quiz_time_duration);
            if (response.data[0].quiz_grace_time !== 0) {
              setGraceDurationToggle(true);
              setGraeDuration(response.data[0].quiz_grace_time);
            }
          }
          setHideAnswerToggle(response.data[0].quiz_instant_feedback_status !== 0 ? true : false);
          setShuffleQuestionToggle(response.data[0].shuffle_quiz_questions !== 0 ? true : false);
          setAutoSubmitionToggle(response.data[0].quiz_auto_submission !== 0 ? true : false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const onChangeQuizName = (e) => {
    setcreateQuiz({ ...createQuiz, [e.target.name]: e.target.value });
  };

  const handleClickChooseQuestionType = () => {
    if (!location.state.classroomData) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("OCSelect Subject [" + subjects[0].subject_name + "]");
    }
    if (location.state.classroomData) {
      selectedSubject.current = subjects.filter((subject) => subject.subject_id === location.state.classroomData.subject.subject_id);
    }
    console.log("DAYA", quizDetails.topic_id, quizDetails.chapter_id);
    if (createQuiz.quizName.length > 0 && quizDetails.topic_id.length > 0 && quizDetails.chapter_id.length > 0) {
      navigate("/question-configuration", {
        state: {
          data: createQuiz,
          formativeQuizTypeStatus: formativeQuizTypeStatus.current,
          hasExamStylePrompt: hasExamStylePrompt.current,
          hasMockExams: hasMockExams.current,
          classroomData: location.state.classroomData,
          url: location.state.url,
          selectedSubject: selectedSubject.current ? selectedSubject.current[0] : location.state.subjectData,
          bannerObject: location.state.bannerObject,
          subjectData: location.state.subjectData,
          sub_topic_ids_csv: quizDetails.topic_id,
          quizName: quizName,
          chapters: chapters,
          topic_id: selectedTopicId.current,
          classroomData: location?.state?.classroomData,
        },
      });
    } else {
      alert("Plese enter all details");
    }
  };

  //CreateQuiz Flow
  useEffect(() => {
    getSubjectsList();
    if (location.state?.classroomData) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("CreateQuiz", "user has visited the create quiz page");
    } else {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("OCCreateQuiz", "user has visited the create quiz page");
    }
  }, []);

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log("objedsdct");
        console.log(res.data);
        setSubjects(res.data);
        var subjects = res.data;
        if (location.state?.classroomData) {
          subjects = subjects.filter((subject) => location.state?.classroomData.subject.subject_id === subject.subject_id);
          hasExamStylePrompt.current = subjects[0].exam_style_quiz_status;
          formativeQuizTypeStatus.current = subjects[0].formative_quiz_type_status;
          hasMockExams.current = subjects[0].has_mock_exams;
          localStorage.setItem("selected_subject_id", subjects[0].subject_id);
          formativeQuizTypeStatus.current = 0;

          // amplitude
          //   .getInstance()
          //   .setUserId(localStorage.getItem("email_address"));
          // amplitude
          //   .getInstance()
          //   .logEvent("Select Subject [" + subjects[0].subject_name + "]");
        }
      });
  };
  return (
    // console.log("NUMBER OF QUESTIONS ARE: ", numberOfQuestions),
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        className="overflow-y-hidden"
        open={quizSettingDialogue}
        onClose={handleCloseQuizSettingDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Task Settings</b>
        </DialogTitle>
        <DialogContent>
          <QuizSetting
            quizType={selectedQuizType}
            quizDuration={quizDurationSetting}
            handleChange={handleChangeSetting}
            quizTiming={quizTiming}
            onIncDecClickQuizDuration={onIncDecClickQuizDuration}
            graceDurationToggle={graceDurationToggle}
            graceDuration={graceDuration}
            onIncDecClickQuizGraceDuration={onIncDecClickQuizGraceDuration}
            autoSubmissionToggle={autoSubmissionToggle}
            hideAnswerToggle={hideAnswerToggle}
            configureToggle={configureToggle}
            shuffleQuestionToggle={shuffleQuestionToggle}
            AIQuestionToggle={AIQuestionToggle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQuizSettingDialogue}>Close</Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialogue
        title={buttonEvent === "1" ? "Share Task" : "Add to drafts"}
        text={buttonEvent === "1" ? "Are you sure you want to share this task with students?" : "Are you sure you want to add this task to drafts?"}
        handleCloseWithConfirm={handleCloseShareQuizConfirmationDialogueWithConfirm}
        handleCloseWithCancel={handleCloseShareQuizConfirmationDialogueWithCancel}
        open={openShareQuizConfirmationDialogue}
      />
      <AcknowledgementDialogue
        title={buttonEvent === "1" ? "Task shared with students!" : "Task added to drafts!"}
        text={
          buttonEvent === "1"
            ? "Navigate to the 'Assigned' tab on your dashboard to access the task"
            : "You can access the task from drafts in dashboard"
        }
        open={openShareQuizAcknowledgementDialogue}
        handleCloseWithConfirm={handleCloseShareQuizAcknowledgementDialogue}
      />
      <ConfirmationDialogue
        open={openDeleteQuizConfirmationDialogue}
        title="Delete"
        text="Are you sure you want to delete this task from drafts?"
        handleCloseWithConfirm={handleCloseDeleteQuizConfirmationDialgueWithConfirm}
        handleCloseWithCancel={handleCloseDeleteQuizConfirmationDialgueWithCancel}
      />
      <AcknowledgementDialogue
        title="Task deleted from drafts!"
        text="The task has been deleted"
        handleCloseWithConfirm={handleCloseDeleteAcknowledgementDialogue}
        open={openDeleteAcknowledgementDialgoue}
      />
      <Dialog
        className="overflow-y-hidden"
        open={openViewLogsDialog}
        onClose={handleViewLogDialogCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Raw Response</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <QuizRawResponseAccordian quizEvaluationLog={quiz_generation_ids_list.current} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewLogDialogCloseWithCancel}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: "20px" }}>
            Hello <span class="wave">👋</span> It looks like you've chosen a lot of subtopics. To get the best results, we recommend selecting fewer
            subtopics.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="delete-button" onClick={handleCloseWithCancel} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorOnShareQuiz}
        onClose={handleCloseWithCancelShareError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: "20px" }}>
            Unable to share quiz with students at this moment. Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="delete-button" onClick={handleCloseWithCancelShareError} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialogue
        open={updateQuizSettingPopUp}
        title="Update"
        text="Do you want to update the task settings?"
        handleCloseWithConfirm={handleCloseUpdateQuizSettingWithConfirm}
        handleCloseWithCancel={handleCloseUpdateQuizSetting}
      />

      <Sidebar item_id="quiz" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        {location.state ? (
          <>
            {location.state.classroomData ? (
              [
                <>
                  {location.state.subjectData && location.state.bannerObject && (
                    <SubscriptionExpirationBanner bannerObject={location.state.bannerObject} subjectData={location.state.subjectData} />
                  )}
                  <ClassroomDetailBanner classroomData={location.state.classroomData} />

                  {id !== null ? (
                    <div className="home sm:ml[25px] md:ml-[60px] underline teacher_navigation_text mt-[1.5vw]">
                      <button className="" onClick={() => navigate(-1)}>
                        {/* {props.text} */}
                        {"Classroom"}
                      </button>
                      <button>{" " + " > View Task"}</button>
                    </div>
                  ) : (
                    <div className="home sm:ml[25px] md:ml-[60px] underline teacher_navigation_text">
                      <button className="" onClick={handleOnClick}>
                        {/* {props.text} */}
                        {"Dashboard"}
                      </button>
                      {/* <button onClick={() => navigate(-1)}>{" " + " > Create Quiz"}</button> */}
                      <button>{" " + " > Create Task"}</button>
                    </div>
                  )}
                </>,
              ]
            ) : (
              <div></div>
            )}
          </>
        ) : null}

        <div className="home sm:ml[25px] w-[70%] md:ml-[5.063rem] mt-[2.5rem]">
          <DashboardLabel text="Step 1: Task Setup" />
          <div className="ml-[1rem]">
            <label className="disabled-label">Step 2: Question Selection & Review</label>
          </div>
          {location.state ? (
            <>
              {!location.state.classroomData ? (
                <div className="pl-10">
                  <label className="disabled-label">Share</label>
                </div>
              ) : (
                <div></div>
              )}
            </>
          ) : null}
          {/* Settings */}
          {location.state && id && (
            <>
              {location.state.classroomData && (
                <div
                  className="ml-auto"
                  style={{
                    display: selectedSubject !== undefined && selectedSubject.exam_paper_quiz_status === 1 && "none",
                  }}
                >
                  <div className="float-right flex">
                    <img src={Setting} style={{ width: "20px", marginRight: "7px" }}></img>
                    <button onClick={() => handleQuizSettingDialogueOpen()} className="disabled-label">
                      Settings
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="form-div mt-[2.604vw]">
          <div className="sm:w-[100%] md:w-[800px]">
            <div className="sm:w-[98%] sm:ml-[3px] md:w-[29.125rem] sm md:ml-[9.375rem]">
              <div className="flex mb-[0.688rem]">
                <label className="create_quiz_label_new mr-1">1. Name Your Task</label>
                <div>
                  <img src={Emergency} />
                </div>
              </div>
              <div>
                <input
                  disabled={lockFields.current}
                  type="text"
                  name="quizName"
                  value={createQuiz.quizName}
                  onChange={onChangeQuizName}
                  id="quizName"
                  maxLength={45}
                  className="teepee-input"
                  placeholder="e.g Task 1"
                  required
                />
              </div>
              <div className="w-full">
                <div className="character-length-label justify-end mr-5 mt-[0.261vw] text-[#605C65]"> Max. 45 characters</div>
              </div>

              {/* <div
                style={{
                  display: id != null || quizTypesList.current.length > 1 || selectedQuizType === 2 ? "block" : "none",
                }}
              >
                <div>
                  <label className="create_quiz_label">1. Select the type of your quiz.</label>
                </div>

                <div className="">
                  <select
                    id="quiz_type"
                    disabled={lockFields.current}
                    onChange={onChange}
                    name="quiz_type"
                    className=" teepee-select shadow-none leading-[30px] font-[300] px-4 w-[100%] h-[100%] bg-transparent"
                  >
                    {quizTypesList.current}
                  </select>
                </div>
              </div> */}

              {selectedQuizType === 2 && (
                <div>
                  <label className="create_quiz_label_new">{"2"}. Select paper type</label>

                  <div className="">
                    <select
                      placeholder="select paper type"
                      id="modules_option"
                      disabled={lockFields.current}
                      onChange={onChange}
                      name="paper"
                      className=" teepee-select shadow-none leading-[30px] font-[300] px-4 w-[100%] h-[100%] bg-transparent"
                    >
                      {id === null && (
                        <option hidden value="select paper type" defaultValue={"Select paper type"}>
                          Select paper type
                        </option>
                      )}
                      {paperList}
                    </select>
                  </div>
                </div>
              )}

              {/* mini-mock=2 */}
              <div style={{ display: selectedQuizType === 2 ? "none" : "block" }}>
                <div className="flex mb-[0.573vw] mt-[1.25vw]">
                  <label className="create_quiz_label_new">2. Select the topic of your choice.</label>
                  <div className="ml-[0.625vw]">
                    <img src={Emergency} />
                  </div>
                </div>
                {selectedQuizType === 1 ? (
                  <div className="">
                    <select
                      placeholder="select topic"
                      id="modules_option"
                      disabled={lockFields.current}
                      onChange={onChange}
                      name="chapter"
                      className=" teepee-select shadow-none leading-[30px] font-[300] px-4 w-[100%] h-[100%] bg-transparent"
                    >
                      {id === null && (
                        <option hidden value="Select topic" defaultValue={"Select topic"}>
                          Select topic
                        </option>
                      )}
                      {chapterList}
                    </select>
                  </div>
                ) : (
                  <div>
                    <FormControl sx={selectFieldStyles} className="w-[100%]">
                      {checkedModuleList.length === 0 ? (
                        <InputLabel
                          shrink={false}
                          style={{
                            letterSpacing: "0.5px",
                            marginTop: "3px",
                            fontSize: "14px",
                            marginLeft: "14px",
                            fontWeight: "600",
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily:
                              'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                          }}
                        >
                          Select Topic
                        </InputLabel>
                      ) : null}

                      <Select
                        style={{ height: "56px" }}
                        className=""
                        disabled={lockFields.current}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        name="chapter"
                        value={checkedModuleList}
                        onChange={onChange}
                        // input={<OutlinedInput label="Select Module" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {chapterList}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div className="mt-[1.25vw] flex mb-[0.573vw]">
                  <label className="create_quiz_label_new">3. Select your preferred subtopic.</label>
                  <div className="ml-[0.625vw]">
                    <img src={Emergency} />
                  </div>
                </div>
                {/* {selectedQuizType === 1 ? (
                  <div>
                    <div className="">
                      <select
                        disabled={lockFields.current}
                        onChange={onChange}
                        name="topic"
                        className=" teepee-select shadow-none leading-[30px]  font-[300] px-4 w-[100%] h-[100%] bg-transparent"
                      >
                        {/* <option value="" defaultValue={"Select topic"} hidden disabled>
                    Select topic
                  </option> */}
                {/* {topicList} */}
                {/* </select> */}
                {/* </div> */}
                {/* </div> */}
                <div>
                  <FormControl sx={selectFieldStyles} className="teepee-select !mb-[2.083vw]">
                    {/* <InputLabel id="demo-multiple-checkbox-label">
                      Select Topic
                    </InputLabel> */}
                    {checkedTopicList.length === 0 ? (
                      <InputLabel
                        shrink={false}
                        style={{
                          letterSpacing: "0.5px",
                          marginTop: "3px",
                          fontSize: "14px",
                          marginLeft: "14px",
                          fontWeight: "600",
                          color: "rgba(0, 0, 0, 0.6)",
                          fontFamily:
                            'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                        }}
                      >
                        Select Subtopic
                      </InputLabel>
                    ) : null}
                    <Select
                      style={{ height: "56px" }}
                      displayEmpty
                      disabled={lockFields.current}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      name="topic"
                      value={checkedTopicList}
                      onChange={onChange}
                      // input={<OutlinedInput label="Select Topic" />}
                      renderValue={(selected) => (selected.length > 0 ? selected.join(", ") : "")}
                      MenuProps={MenuProps}
                    >
                      {topicList}
                    </Select>
                  </FormControl>
                </div>
                {/* <div>
                  <label className="create_quiz_label">
                    {quizTypesList.current.length > 1 || id !== null ? "4" : "3"}. Choose your desired areas.
                  </label>
                </div> */}
                {/* <div>
                  <div className="subtopic_checkbox">
                    <div
                      id="sub_topics_container"
                      className="p-2.5 bg-white shadow-sm outline-none rounded-[8px] border-[1px] border-[#474747] w-full h-[7srem] text-[#474747] leading-[30px] text-[20px] font-[500] mb-1"
                    >
                      <ul className="h-24 px-3 overflow-y-auto text-sm text-black dark:text-black" aria-labelledby="dropdownSearchButton">
                        {subTopicList}
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* {(numberOfQuestions > 0 || id !== null) && (
                  <div className="mb-5">
                    <div>
                      <label className="create_quiz_label">
                        {quizTypesList.current.length > 1 || id !== null ? "5" : "4"}. Select the number of questions for your quiz
                      </label>
                    </div>
                    <div>
                      <div>
                        <select
                          id="number_of_questions"
                          disabled={lockFields.current}
                          value={quizDetails.numOfQuestions}
                          name="numOfQuestions"
                          onChange={onChange}
                          className="p-2.5 bg-white shadow-sm outline-none rounded-[5px] border-[1px] border-[#474747] w-full h-[50px] text-[#474747] leading-[30px] text-[20px] font-[500] px-4"
                        >
                          {[
                            ...Array(
                              selectedSubject !== undefined && selectedSubject.exam_paper_quiz_status !== 1
                                ? numberOfQuestions > 10
                                  ? 10
                                  : numberOfQuestions
                                : numberOfQuestions
                            ),
                          ]
                            .map((_, i) => i + 1)
                            .map((i) => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>

              <div style={{ display: id != null ? "none" : "block" }}>
                <div className="dashboard_buttons !block">
                  {/* <button className={userType === "2" ? "teepee-back-button sm:w-[30%] md:w-[46%]" : "teepee-back-button w-[46%]"} onClick={goBack}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img alt="" src={BackArrow} className="pr-4"></img>
                      <div>Go Back</div>
                    </div>
                  </button> */}

                  {/* <button
                    style={{ display: userType === "2" ? "" : "none" }}
                    onClick={() => {
                      handleRawResponseClick();
                    }}
                    className="teepee-button md:ml-12 hover:bg-black sm:w-[30%] md:w-[30%]"
                  >
                    View Logs
                  </button> */}

                  <button
                    id="button"
                    // onClick={handleSubmit(submitForm)}
                    onClick={handleClickChooseQuestionType}
                    disabled={isSubmitting || lockFields.current}
                    className={
                      userType === "2" ? "teepee-button md:ml-12 hover:bg-black sm:w-full md:w-full" : "teepee-button md:ml-12 hover:bg-black w-full"
                    }
                  >
                    {isSubmitting && (
                      <div style={{ height: "26px", width: "25px" }} className="spinner-border spinner-border-m float-left" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    Choose Question Types
                  </button>
                </div>
              </div>
            </div>

            {isSubmitting && (
              <div id="quiz-section" className="w-[100%] sm:pt-[30px] ">
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#B3B3B3",
                    marginTop: "0px",
                  }}
                />
                <img alt="" src={magicianImage} style={{ margin: "auto", width: "180px" }} className="sm:pt-[30px] md:pt-0"></img>
                <p
                  style={{
                    fontSize: "21px",
                    fontFamily: "Roboto",
                    fontStyle: "Bold",
                    fontWeight: "500",
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  Magically generating task
                </p>

                <p
                  style={{
                    fontSize: "16px",
                    marginBottom: "100px",
                    fontFamily: "Roboto",
                    textAlign: "center",
                    marginTop: "1px",
                  }}
                >
                  Our AI can take up to 60 seconds…
                </p>
              </div>
            )}

            <div
              id="quiz-section"
              style={{
                display: question_answers.current.length === 0 && mock_exam === null ? "none" : "",
              }}
              className="sm:ml-[3px] sm:w-[98%] md:w-[100%] sm:pt-[20px]"
            >
              <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "#B3B3B3",
                  marginTop: "50px",
                }}
              />
              <p
                style={{
                  marginBottom: "50px",
                  fontFamily: "Roboto",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {selectedQuizType === 1 || selectedQuizType === 3 ? "Here is your task 👇" : "Your mini-mock exam paper 👇"}
              </p>

              {(selectedQuizType === 1 || selectedQuizType === 3 || mock_exam === null) &&
                response_data?.map((rowData, index) => (
                  <Fragment key={index}>
                    <div
                      ref={parentRef}
                      className={
                        replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1
                          ? "w-[102%]   relative bg-[#CEF2CE] "
                          : ""
                      }
                    >
                      <div
                        className={
                          replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1 ? "relative " : ""
                        }
                      >
                        <div
                          ref={grandchildRef}
                          // style={{
                          //   background: "#E1E1E1",
                          //   padding: "10px",
                          //   borderRadius: "5px",
                          // }}

                          className={
                            replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1
                              ? " p-[10px] pt-[20px] pl-[8px] md:pb-[70px] sm:pb-[130px] 		"
                              : ""
                          }
                        >
                          <div className="md:w-[800px] ">
                            <div
                              style={{
                                padding: "10px ",
                                borderRadius: "5px",
                                width: "100%",
                                marginBottom: "20px",
                                backgroundColor: "#F0F0F0",
                              }}
                            >
                              <p
                                className="answer-paragraph"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Roboto",
                                  color: "#49454F",
                                  display: "flex",
                                }}
                              >
                                <span className="w-[87%]">
                                  <b>Question {index + 1}: </b>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: removeHtmlTags(rowData.question),
                                    }}
                                  ></span>
                                </span>

                                <b className="sm:w-[35%] md:w-[10%]"> [Marks: {rowData.question_marks}]</b>
                              </p>
                              {rowData.image_file_name !== null && (
                                <div className="flex justify-center mb-[20px] mt-[20px]">
                                  <img alt="" className="" src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + rowData.image_file_name}></img>
                                </div>
                              )}
                              <p
                                className="answer-paragraph"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Roboto",
                                  color: "#49454F",
                                  display: isAnswerRubric === 1 ? "none" : "",
                                }}
                              >
                                <b>Answer: </b>
                                {rowData.answer.split("Rubric:")[0]}
                              </p>

                              {selectedQuizType !== 1 && rowData.answer.split("Rubric:").length > 1 && (
                                <p
                                  className="answer-paragraph"
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Roboto",
                                    color: "#49454F",
                                    display: isAnswerRubric === 1 ? "none" : "",
                                  }}
                                >
                                  <b>Rubric: </b>
                                  {rowData.answer.split("Rubric:")[1]}
                                </p>
                              )}
                            </div>
                            {/* <button
                    onClick={removeQuestion}
                    value={index}
                    style={{ height: "50px", float: "right", fontSize: "14px" }}
                    className="bg-[#edeaf4] text-[#6750A4]  px-4 "
                  >
                     <span className="spinner-grow spinner-grow-sm"></span>
                    Remove
                  </button> */}
                            <div>
                              <div
                                className="close bg-[white] text-[black]  px-4 mr-[100px] pt-[12px] flex sm:mb-[20px]"
                                aria-label="Close"
                                style={{
                                  display: rowData.sub_topic_name ? "flex" : "none",
                                  height: "50px",
                                  float: "left",
                                  fontSize: "16px",
                                  borderRadius: "5px",
                                  width: "250px",
                                  border: "1px solid black",
                                }}
                                // className=" "
                              >
                                <span className="pr-[10px] text-[#6750A4] subtopic_name" aria-hidden="true" style={{ width: "170px" }}>
                                  {rowData.sub_topic_name}
                                </span>
                                <div className="mt-[3px]">
                                  <img src={infoLogo}></img>
                                </div>{" "}
                                {/* {sub_topic_name.current[index]} */}
                              </div>
                              <button
                                type="button"
                                className="close bg-[#edeaf4] text-[#6750A4]  px-4"
                                aria-label="Close"
                                onClick={() => removeQuestion(index)}
                                value={index}
                                style={{
                                  display: id != null ? "none" : "block",
                                  height: "50px",
                                  float: "right",
                                  fontSize: "16px",
                                  borderRadius: "5px",
                                }}
                                // className=" "
                              >
                                <span className="pr-[10px]" aria-hidden="true">
                                  &times;
                                </span>
                                Remove
                              </button>
                              <button
                                data-modal-target="defaultModal"
                                type="button"
                                className="close bg-[#edeaf4] text-[#6750A4]  px-4 mr-[10px]"
                                aria-label="Close"
                                // onClick={() => <ReplaceDialogue />}
                                onClick={() => handleClickOpen(index)}
                                value={index}
                                style={{
                                  display: id != null ? "none" : "flex",
                                  height: "50px",
                                  float: "right",
                                  fontSize: "16px",
                                  borderRadius: "5px",
                                  alignItems: "center",
                                }}
                                // className=" "
                              >
                                <span className="pr-[10px]" aria-hidden="true">
                                  <img src={ReplaceLogo}></img>
                                </span>
                                Replace
                              </button>
                              <ReplaceDialogue
                                openReplaceDialogue={openReplaceDialogue}
                                handleClose={handleClose}
                                chapterList={chapters}
                                questionIndex={replaceQuestionIndex}
                                replaceQuestion={replaceQuestion}
                                quizType={quizDetails.quizType}
                                blackListedQuestionArray={blackListedQuestionArray}
                                handleRawResponseClick={handleRawResponseClick}
                                numberOfQuestions={numberOfQuestions}
                                blackListedQuestionsToAvoid={blackListedQuestionsToAvoid.current}
                                bannerObject={location.state !== null && location.state.bannerObject && location.state.bannerObject}
                                isAnswerRubric={isAnswerRubric}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        replacedQuestionIndexStatus.current.length > 0 && replacedQuestionIndexStatus.current[index] === 1
                          ? "sm:mt-[80px] md:mt-[20px] "
                          : "sm:mt-[150px] md:mt-[100px]"
                      }
                      style={{
                        flex: 1,
                        height: "1px",
                        backgroundColor: "#B3B3B3",
                        // marginTop:
                        //   replacedQuestionIndexStatus.current.length > 0 &&
                        //   replacedQuestionIndexStatus.current[index] === 1
                        //     ? "20px"
                        //     : "100px",
                        marginBottom: "10px",
                      }}
                    />
                  </Fragment>
                ))}

              {selectedQuizType === 2 && mock_exam !== null && (
                <b style={{ fontSize: "16px" }}>Exam Duration: {quizTiming === 0 ? mock_exam.mock_exam_duration : quizTiming} Minutes</b>
              )}

              {selectedQuizType === 2 &&
                mock_exam !== null &&
                JSON.parse(mock_exam.question_context_list)?.map((question_context, index) => (
                  <Fragment key={index}>
                    <div style={{}}>
                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "30px",
                          backgroundColor: "#F0F0F0",
                        }}
                      >
                        <p
                          className="answer-paragraph"
                          style={{
                            display: "flex",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            color: "#49454F",
                          }}
                        >
                          <span className="w-[87%]">
                            <b>Questions {index + 1}: </b>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHtmlTags(question_context.question_context_name),
                              }}
                            ></span>
                          </span>
                        </p>
                        {JSON.parse(question_context.predefined_question_answer_list)?.map((predefined_question_answer, index) => (
                          <div className="m-[30px] mb-[30px]">
                            <p
                              style={{
                                display: "flex",
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#49454F",
                              }}
                            >
                              <b className="mr-[5px]">({String.fromCharCode(index + "A".charCodeAt(0)).toLowerCase()})</b>
                              <p
                                className="w-[87%]"
                                dangerouslySetInnerHTML={{
                                  __html: predefined_question_answer.question,
                                }}
                              ></p>
                              {selectedQuizTypeId.current === 2 && (
                                <b className="sm:w-[40%] md:w-[20%]">[Marks: {predefined_question_answer.question_marks}]</b>
                              )}
                            </p>
                            {predefined_question_answer.image_file_name !== null && (
                              <div className="flex justify-center mb-[20px] mt-[20px]">
                                <img
                                  alt=""
                                  className=""
                                  src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + predefined_question_answer.image_file_name}
                                ></img>
                              </div>
                            )}
                            <AnswerAccordian
                              display_rubric={isAnswerRubric === 1 ? false : true}
                              title="Answer"
                              predefined_question_answer={predefined_question_answer}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Fragment>
                ))}

              <button
                style={{
                  display: location.state ? (location.state.selectedQuizType === 1 ? "none" : "block") : "block",
                }}
                value={1}
                onClick={(e) => handleOpenShareQuizConfirmationDialogue(e.target.value)}
                disabled={isSubmittingShare}
                className="teepee-button ml-12 hover:bg-black w-[full] mb-[20px]  sm:w-[100%]"
              >
                {/* {isSubmittingShare && (
                  <div
                    className="spinner-border spinner-border-m float-left"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )} */}
                Share the task with students
              </button>
              <button
                style={{
                  display: location.state
                    ? location.state.selectedQuizType
                      ? location.state.selectedQuizType === 2
                        ? "block"
                        : "none"
                      : "none"
                    : "none",
                }}
                onClick={(e) => handleOpenDeleteQuizConfirmationDialgue(e)}
                disabled={isSubmittingShare}
                className="teepee-back-button hover:bg-black hover:text-white w-[full] mb-[40px]  sm:w-[100%]"
              >
                {
                  isSubmittingShare && <div></div>
                  // (
                  //   <div
                  //     className="spinner-border spinner-border-m float-left"
                  //     role="status"
                  //   >
                  //     <span className="sr-only">Loading...</span>
                  //   </div>
                  // )
                }
                Delete from drafts
              </button>
              {location.state ? (
                <>
                  {location.state.classroomData ? (
                    <button
                      style={{
                        display: location.state
                          ? location.state.selectedQuizType === 2
                            ? "none"
                            : location.state.selectedQuizType === 1 && "none"
                          : "block",
                      }}
                      value={2}
                      onClick={(e) => {
                        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
                        amplitude.getInstance().logEvent("AddtoDrafts");
                        handleOpenShareQuizConfirmationDialogue(e.target.value);
                      }}
                      disabled={isSubmittingShare}
                      className="teepee-back-button hover:bg-black hover:text-white w-[full] mb-[40px]  sm:w-[100%]"
                    >
                      {
                        isSubmittingShare && <div></div>
                        // (
                        //   <div
                        //     className="spinner-border spinner-border-m float-left"
                        //     role="status"
                        //   >
                        //     <span className="sr-only">Loading...</span>
                        //   </div>
                        // )
                      }
                      Add to drafts
                    </button>
                  ) : (
                    <div> </div>
                  )}
                </>
              ) : null}

              <div className="app" style={{ textAlign: "center" }}></div>
              <div>
                {/* <form onSubmit={handleSubmit(submitForm)}>
          <div className="mt-2">
              <button disabled={isSubmitting} className="btn btn-danger">
                {isSubmitting && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                Submit
              </button>
          </div>
      </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDetails;
