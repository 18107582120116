import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Dashboard from "./Componenets/Dashboard";

const ProtectedWebUserRoute = () => {
  const [webUser, setWebUser] = useState(
    localStorage.getItem("user_type") === "1" ||
      localStorage.getItem("user_type") === "2"
      ? true
      : null
  );

  return webUser ? <Dashboard /> : <Navigate to="/student-dashboard" />;
};
export default ProtectedWebUserRoute;
