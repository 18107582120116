import { Progress } from "@material-tailwind/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { CSVLink } from "react-csv";
import lateSubmissionLogo from "../images/ic_warning_triangle.svg";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import amplitude from "amplitude-js";
import Tooltip from "@mui/material/Tooltip";
import QuizRawResponseAccordian from "./QuizRawResponseAccordian";
import ReEvaluateButton from "./ReEvaluateButton";
import ClassroomDetailBanner from "./Teacher/widgets/ClassroomDetailBanner";
import TeacherNavigationText from "./Teacher/widgets/TeacherNavigationText";
import QuizAnalyticsTabs from "./widgets/quiz_analytics_tabs/QuizAnalyticsTabs";
import ComingSoon from "./widgets/ComingSoon";
function ViewResult() {
  const location = useLocation();
  const { quiz_title } = location.state;
  const userType = localStorage.getItem("user_type");
  const { created_timestamp } = location.state;
  const { quiz_total_marks } = location.state;
  const { quiz_duration } = location.state;
  const { quiz_grace_time } = location.state;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [quizData, setQuizData] = useState([]);
  const [quizDataForCSV, setQuizDataForCSV] = useState([]);
  const [quizEvaluationLog, setQuizEvaluationLog] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [quizType, setQuizType] = useState(1);
  const tableSelection = useRef(1);
  const url = useRef("");
  var id = params.get("id");
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [marks, setMarks] = useState([]);
  const [averagePercentage, setAveragePercentage] = useState([]);
  const [averageScore, setAverageScore] = useState([]);
  const [areaNames, setAreaNames] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [subtopicData, setSubtopicData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [subtopicPercentage, setSubtopicPercentage] = useState([]);
  const [areaPercentage, setAreaPercentage] = useState([]);
  const [studentName, setStudentName] = useState("");
  const [student, setStudent] = useState();
  const [questionMarks, setQuestionMarks] = useState([]);
  const [minimockQuestionSequence, setQuestionSequence] = useState([]);
  const [totalAreaMarks, setTotalAreaMarks] = useState([]);
  const [comingSoonDialogue, setComingSoonDialogue] = useState(false);
  const [tableDataForCSV, setTableDataForCSV] = useState([]);
  const [questionClassPercentage, setQuestionClassPercentage] = useState([]);

  const handleOpenComingSoonDialogue = () => {
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("DownloadCSVQuizAnalysis");
    setComingSoonDialogue(true);
  };
  const handleCloseComingSoonDialogue = () => {
    setComingSoonDialogue(false);
  };
  //const headersForMiniMock = [{ label: "Student Name", key: "student_name" },{ label: "Date", key: "submitted_timestamp" },{label: "Late Submission(min)", key:"quiz_attempt_duration"},{label: "Marks"+"score", key:"score"},{ label: "Performance", key: "performance" }];
  const csvReportForMiniMock = {
    data: quizDataForCSV,
    // headers: headersForMiniMock,
    filename: quiz_title + "_quiz_result.csv",
  };

  const handleChange = (event) => {
    tableSelection.current = event.target.value;
    var url = null;
    if (event.target.value === 1 && localStorage.getItem("student") === null) {
      url = "/teacher_portal_quiz_level_analytics";
    } else if (event.target.value === 2 && localStorage.getItem("student") === null) {
      url = "/teacher_portal_quiz_level_area_analytics";
    } else if (event.target.value === 1 && localStorage.getItem("student") !== null) {
      url = "/teacher_portal_quiz_level_analytics_individual_student";
    } else if (event.target.value === 2 && localStorage.getItem("student") !== null) {
      url = "/teacher_portal_quiz_level_area_analytics_individual_student";
    }
    // const url =
    //   event.target.value === 1
    //     ? "/teacher_portal_quiz_level_analytics"
    //     : event.target.value === 2
    //     ? "/teacher_portal_quiz_level_area_analytics"
    //     : "";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
        method: "POST",
        quiz_secret_key: id,
        quiz_response_id: student !== undefined ? student.student_id : "",
      })
      .then((response) => {
        console.log(response.data);
        setTableData(response.data.table_data);
        setMarks(response.data.table_data[0].marks);
        setAverageScore(response.data.average_scores);
        setAveragePercentage(response.data.average_percentages);
        setQuestionMarks(response.data.question_marks_list);
        setAreaNames(response.data.area_name);

        if (tableSelection.current === 2) {
          setAreaNames(response.data.area_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleIndividualQuiz = (student) => {
    setStudent(student);
    tableSelection.current = 2;
    setStudentName(student.student_names);
    localStorage.setItem("student", student.student_names);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/teacher_portal_quiz_level_area_analytics_individual_student", {
        method: "POST",
        quiz_secret_key: id,
        quiz_response_id: student.student_id,
      })

      .then((response) => {
        console.log(response.data);
        setTableData(response.data.table_data);
        setMarks(response.data.table_data[0].marks);
        setAverageScore(response.data.average_scores);
        setAveragePercentage(response.data.average_percentages);
        if (tableSelection.current === 2) {
          setAreaNames(response.data.area_name);
          setTopicData(response.data.topic_percentage_);
          setSubtopicData(response.data.topic_has_subtopic_list_new_data);
          setAreaData(response.data.subtopic_has_area_list_new_data);
          setSubtopicPercentage(response.data.subtopic_percentage);
          setAreaPercentage(response.data.average_percentages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    localStorage.removeItem("student");
    get_list_of_attempted_students();
  }, []);

  const get_list_of_attempted_students = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        quiz_secret_key: id,
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_attempted_students_list",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          quiz_secret_key: id,
        }),
      })
      .then((res) => {
        console.log(res.data);
        var quiz_responses_list = JSON.parse(res.data["quiz_responses_list"]);
        setQuizData(quiz_responses_list);
        localStorage.setItem("quiz_type", res.data["quiz_type"]);
        if (res.data["quiz_type"] === 1) {
          if (quiz_duration !== 0) {
            prepareQuizDataForExamStyle(quiz_responses_list);
          } else {
            prepareQuizDataForExamStyleWithoutTime(quiz_responses_list);
          }
        } else if (res.data["quiz_type"] === 2) {
          prepareQuizDataForMiniMock(quiz_responses_list);
        } else if (res.data["quiz_type"] === 3) {
          if (quiz_duration !== 0) {
            prepareQuizDataForExamStyle(quiz_responses_list);
          } else {
            prepareQuizDataForExamStyleWithoutTime(quiz_responses_list);
          }
        }

        if (res.data["quiz_type"] === 2) {
          tableSelection.current = 1;
          url.current = "/teacher_portal_quiz_level_analytics_minimock";
          console.log("MINI MOCK CASE");
        } else {
          tableSelection.current = 2;
          url.current = "/teacher_portal_quiz_level_area_analytics";
          console.log("OTHER QUIZ CASE:");
        }
        var token = "Bearer " + localStorage.getItem("access_token");
        axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
        axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
        axios.defaults.headers.post["authorization"] = token;
        axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

        axios
          .post(process.env.REACT_APP_REST_API_BASE_URL + url.current, {
            method: "POST",
            quiz_secret_key: id,
          })
          .then((response) => {
            console.log("response of the analytics", response.data);
            setTableData(response.data.table_data);
            setMarks(response.data.table_data[0].marks);
            setAverageScore(response.data.average_scores);
            setAveragePercentage(response.data.average_percentages);
            setTableDataForCSV(response.data.table_data2);

            if (tableSelection.current === 2) {
              setAreaNames(response.data.area_name);
              setTopicData(response.data.topic_percentage_);
              setSubtopicData(response.data.topic_has_subtopic_list_new_data);
              setAreaData(response.data.subtopic_has_area_list_new_data);
              setSubtopicPercentage(response.data.subtopic_percentage);
              setAreaPercentage(response.data.average_percentages);
              setTotalAreaMarks(response.data.total_marks_of_area);
              setQuestionClassPercentage(response.data.average_percentages2);
            }
            if (res.data["quiz_type"] === 2) {
              setQuestionMarks(response.data.question_marks_list);
              setQuestionSequence(response.data.question_sequence);
            }
          })
          .catch((error) => {
            console.log(error);
          });

        setQuizType(res.data["quiz_type"]);
      });
  };
  //eadersForMiniMock = [{ label: "Student Name", key: "student_name" },{ label: "Date", key: "submitted_timestamp" },{label: "Late Submission(min)", key:"quiz_attempt_duration"},{label: "Marks"+"score", key:"score"},{ label: "Performance", key: "performance" }];
  const prepareQuizDataForMiniMock = (quiz_responses_list) => {
    var quiz_responses_list_temp = [["", "", "Duration(min)", quiz_duration / 60, "", "", ""]];
    quiz_responses_list_temp.push([
      "Sr. No.",
      "Student Name",
      "Time Taken (min)",
      "Date",
      "Late Submission (min)",
      "Marks (" + quiz_total_marks + ")",
      "Percentage (%)",
    ]);
    var late_submission_duration;

    for (var index = 0; index < quiz_responses_list.length; index++) {
      late_submission_duration = "";
      if (quiz_responses_list[index]["quiz_attempt_duration"] > quiz_duration + (quiz_grace_time === undefined ? 0 : quiz_grace_time)) {
        late_submission_duration = getTimeString(
          quiz_duration + (quiz_grace_time === undefined ? 0 : quiz_grace_time) - quiz_responses_list[index]["quiz_attempt_duration"]
        );
      }
      quiz_responses_list_temp.push([
        index + 1,
        quiz_responses_list[index]["student_name"],
        parseInt(quiz_responses_list[index]["quiz_attempt_duration"] / 60),
        quiz_responses_list[index]["submitted_timestamp"],
        late_submission_duration,
        ((quiz_total_marks / 100) * quiz_responses_list[index]["overall_score"]).toFixed(0),
        quiz_responses_list[index]["performance"],
      ]);
    }

    setQuizDataForCSV(quiz_responses_list_temp);
  };

  const prepareQuizDataForExamStyle = (quiz_responses_list) => {
    var quiz_responses_list_temp = [];
    quiz_responses_list_temp.push([
      "Sr. No.",
      "Student Name",
      "Time Taken (min)",
      "Date",
      "Late Submission (min)",
      "Marks (" + quiz_total_marks + ")",
      "Percentage (%)",
    ]);
    var late_submission_duration;

    for (var index = 0; index < quiz_responses_list.length; index++) {
      late_submission_duration = "";
      if (quiz_responses_list[index]["quiz_attempt_duration"] > quiz_duration + (quiz_grace_time === undefined ? 0 : quiz_grace_time)) {
        late_submission_duration = getTimeString(
          quiz_duration + (quiz_grace_time === undefined ? 0 : quiz_grace_time) - quiz_responses_list[index]["quiz_attempt_duration"]
        );
      }
      quiz_responses_list_temp.push([
        index + 1,
        quiz_responses_list[index]["student_name"],
        parseInt(quiz_responses_list[index]["quiz_attempt_duration"] / 60),
        quiz_responses_list[index]["submitted_timestamp"],
        late_submission_duration,
        ((quiz_total_marks / 100) * quiz_responses_list[index]["overall_score"]).toFixed(0),
        quiz_responses_list[index]["performance"],
      ]);
    }

    setQuizDataForCSV(quiz_responses_list_temp);
  };

  const prepareQuizDataForExamStyleWithoutTime = (quiz_responses_list) => {
    var quiz_responses_list_temp = [];
    quiz_responses_list_temp.push(["Sr. No.", "Student Name", "Date", "Marks (" + quiz_total_marks + ")", "Percentage (%)"]);

    for (var index = 0; index < quiz_responses_list.length; index++) {
      quiz_responses_list_temp.push([
        index + 1,
        quiz_responses_list[index]["student_name"],
        quiz_responses_list[index]["submitted_timestamp"],
        ((quiz_total_marks / 100) * quiz_responses_list[index]["overall_score"]).toFixed(0),
        quiz_responses_list[index]["performance"],
      ]);
    }

    setQuizDataForCSV(quiz_responses_list_temp);
  };

  const handleSeeMoreClick = (e) => {
    if (location.state.classroomData) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("ICSeeMore");
    }
    var selectedQuiz = quizData[e.target.value];

    // var quizResponseId = e.target.value;
    //  var studentName = e.target.name;
    // const quiz = quizData.filter(
    //   (quiz) => quiz.quiz_response_id !== quizResponseId
    // );
    console.log("filtered quiz", selectedQuiz.performance);
    navigate("/quiz-detail-review", {
      state: {
        student_name: selectedQuiz.student_name,
        quiz_response_id: selectedQuiz.quiz_response_id,
        title: quiz_title,
        quiz_performace: selectedQuiz.performance,
        quiz_secret_key: id,
        quiz_total_marks: quiz_total_marks,
        rubric: false,
      },
    });
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
  };

  const handleRawResponseClick = (quiz_response_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        quiz_secret_key: id,
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_evaluation_log",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          quiz_response_id: quiz_response_id,
        }),
      })
      .then((res) => {
        console.log(res.data);
        var quiz_evaluation_log_list = JSON.parse(res.data["quiz_evaluation_log_list"]);
        setQuizEvaluationLog(quiz_evaluation_log_list);
        setOpen(true);
      });
  };

  function getTimeString(seconds) {
    seconds *= -1;

    if (seconds >= 60) {
      return Math.floor(seconds / 60) + " min";
    }

    return seconds + " sec";

    // var total = seconds * 1000;
    // total *=-1
    // seconds = Math.floor((total / 1000) % 60);
    // const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    // return (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds)
  }

  const handlerPerformance = (quiz) => {
    var performancePercentageStr = " (" + quiz.performance + "%)";

    if (quiz.partially_evaluated === 1) {
      return "Re-evaluate";
    }

    if (quiz.performance <= -1) {
      return "Not evaluateable";
    }

    if (quiz.performance >= 80) {
      return "Exceptional" + performancePercentageStr;
    }

    if (quiz.performance >= 70) {
      return "Good" + performancePercentageStr;
    }

    if (quiz.performance >= 50) {
      return "Satisfactory" + performancePercentageStr;
    }

    if (quiz.performance >= 0) {
      return "Unsatisfactory" + performancePercentageStr;
    }
    return "⚠️ Result awaited";
  };

  // useEffect(()=>{

  // },[])

  return (
    <>
      <Dialog
        className="overflow-y-hidden"
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Raw Response</b>
        </DialogTitle>
        <DialogContent className="overflow-y-auto hide-scrollbar">
          <QuizRawResponseAccordian quizEvaluationLog={quizEvaluationLog} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="overflow-y-hidden"
        open={comingSoonDialogue}
        onClose={handleCloseComingSoonDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{/* <b>Work Under Progress</b> */}</DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <center>
            <ComingSoon />
          </center>
        </DialogContent>
      </Dialog>
      <Sidebar item_id="quiz" />

      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[315px]">
        {location.state ? (
          <>
            {location.state.classroomData ? (
              [
                <ClassroomDetailBanner classroomData={location.state.classroomData} />,
                <div style={{ marginLeft: "50px", marginTop: "8px" }}>
                  <div className="home sm:ml[25px] md:ml-[10px] underline teacher_navigation_text">
                    <button className="" onClick={() => navigate(-1)}>
                      {/* {props.text} */}
                      {"Classroom"}
                    </button>
                    <button>{" " + " > View Result"}</button>
                  </div>
                </div>,
                <div>
                  <QuizAnalyticsTabs
                    selectedStudent={student}
                    totalAreaMarks={totalAreaMarks}
                    handleIndividualQuiz={handleIndividualQuiz}
                    handleChange={handleChange}
                    minimockQuestionSequence={minimockQuestionSequence}
                    questionMarks={questionMarks}
                    averageScore={averageScore}
                    averagePercentage={averagePercentage}
                    tableData={tableData}
                    marks={marks}
                    areaNames={areaNames}
                    tableSelection={tableSelection.current}
                    areaPercentage={areaPercentage}
                    areaData={areaData}
                    subtopicPercentage={subtopicPercentage}
                    subtopicData={subtopicData}
                    topicData={topicData}
                    studentName={studentName}
                    quiz_grace_time={quiz_grace_time}
                    url={url.current}
                    quiz_title={quiz_title}
                    created_timestamp={created_timestamp}
                    quizType={quizType}
                    quiz_duration={quiz_duration}
                    csvReportForMiniMock={csvReportForMiniMock}
                    quiz_total_marks={quiz_total_marks}
                    quizData={quizData}
                    getTimeString={getTimeString}
                    handlerPerformance={handlerPerformance}
                    get_list_of_attempted_students={get_list_of_attempted_students}
                    handleSeeMoreClick={handleSeeMoreClick}
                    userType={userType}
                    handleRawResponseClick={handleRawResponseClick}
                    handleOpenComingSoonDialogue={handleOpenComingSoonDialogue}
                    tableDataForCSV={tableDataForCSV}
                    questionClassPercentage={questionClassPercentage}
                  />
                </div>,
              ]
            ) : (
              <div>
                <div className=" flex sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[110px] mt-[16px]">
                  <div className="student-info md:w-[50%]">
                    <label className="teepee-label  ">Task Name:{quiz_title}</label>
                  </div>
                </div>
                <div className=" flex sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[110px]">
                  <div className="quiz-info-light md:items-center w-[80%]">
                    {created_timestamp}
                    <div className="">
                      {quizType === 2 && <>&#8226; {quiz_duration / 60 + " min "}</>}
                      &#8226;
                      {quizType === 1 ? " formative quiz" : quizType === 2 ? " mini-mock" : " exam style"}
                    </div>
                  </div>
                  <div className="teepee-label justify-end w-[40%] ">
                    <CSVLink
                      className="border-[1px] p-2 md:text-[14px] sm:text-[8px] text-gray border-[#8E8888] rounded-[10px]"
                      style={{ color: "#8E8888", textDecoration: "none" }}
                      onClick={() => {
                        if (location.state.classroomData) {
                          amplitude
                            .getInstance()
                            .logEvent(`ICDownload CSV ${quizType === 1 ? "(formative quiz)" : quizType === 2 ? "(mini-mock)" : "(exam style)"}`);
                        } else {
                          amplitude
                            .getInstance()
                            .logEvent(`OCDownload CSV ${quizType === 1 ? "(formative quiz)" : quizType === 2 ? "(mini-mock)" : "(exam style)"}`);
                        }
                      }}
                      {...csvReportForMiniMock}
                    >
                      Download CSV
                    </CSVLink>
                  </div>
                </div>
                <div className="sm:pl-[10px] sm:pr-[10px] md:pr-0 md:pl-[60px] mt-[20px] flex view-quiz-table sm:overflow-scroll md:overflow-hidden md:ml-[55px]">
                  <table id="studenTable" className="w-[100%]">
                    <tr className="tr">
                      <th className="md:w-[70px]">Sr#</th>
                      <th className="sm:text-center md:text-left sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0 ">Student Name</th>
                      <th className="sm:pr-[20px] md:pr-0" style={{ textAlign: "center" }}>
                        Date
                      </th>

                      <th className="sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0" style={{ textAlign: "center" }}>
                        Performance
                      </th>
                      <th className="sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0" style={{ textAlign: "center" }}>
                        Marks ({quiz_total_marks})
                      </th>
                      <th></th>
                    </tr>
                    {quizData.map((quiz, key) => {
                      return (
                        <>
                          <tr className="tr" key={key}>
                            <td>{key + 1 + "."}</td>
                            <td className="sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0">{quiz.student_name}</td>
                            <td className="sm:pr-[40px] sm:pl-[20px] md:pr-0 md:pl-0" style={{ textAlign: "center" }}>
                              <div
                                className="inline-flex"
                                style={{
                                  marginTop: quizType === 2 && quiz.quiz_attempt_duration > quiz_duration ? "10px" : "0px",
                                }}
                              >
                                {/* if quiz type is mini-mock and quiz attempt duration is greater then quiz_duration then displaying late submission warning icon */}
                                {quizType === 2 && quiz.quiz_attempt_duration > quiz_duration && (
                                  <Tooltip title={"Late Submission: " + getTimeString(quiz_duration - quiz.quiz_attempt_duration)}>
                                    <img alt="" src={lateSubmissionLogo}></img>
                                  </Tooltip>
                                )}
                                {quiz.submitted_timestamp}
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <div className="performace-label">
                                {handlerPerformance(quiz)}
                                {quiz.partially_evaluated === 1 && (
                                  <ReEvaluateButton
                                    quiz_secret_key={id}
                                    quiz={quiz}
                                    get_list_of_attempted_students={get_list_of_attempted_students}
                                  />
                                )}
                              </div>
                              <div className="flex w-full flex-col gap-4">
                                <Progress
                                  value={quiz.performance}
                                  color={
                                    quiz.performance >= 80
                                      ? "green"
                                      : quiz.performance >= 70
                                      ? "light-green"
                                      : quiz.performance >= 50
                                      ? "yellow"
                                      : "red"
                                  }
                                />
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }}>{((quiz_total_marks / 100) * quiz.overall_score).toFixed(0)}</td>

                            <td className="text-center">
                              <button
                                id="button"
                                name={quiz.student_name}
                                value={key}
                                onClick={handleSeeMoreClick}
                                className="my-quizzes-card-buttons my-quizzes-card-view-quiz-button"
                              >
                                See more
                              </button>
                            </td>
                            <td
                              style={{
                                display: userType === "2" ? "" : "none",
                              }}
                            >
                              <button
                                id="button1"
                                name={quiz.student_name}
                                value={key}
                                onClick={() => {
                                  handleRawResponseClick(quiz.quiz_response_id);
                                }}
                                className="my-quizzes-card-buttons my-quizzes-card-view-quiz-button"
                              >
                                Raw Response
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </div>
              </div>
            )}
          </>
        ) : null}
        {/* <div className="home">
          <div className="view-result-quiz-name">{quiz_title}</div>
          <div style={{float:"right"}} className="">{quizType}</div>
        </div> */}
      </div>
    </>
  );
}

export default ViewResult;
