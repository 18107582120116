import React from "react";
import Inventory from "../../../images/inventory.png";

function StudentDoneThemCard(props) {
  const doneThem = props.doneThem;
  const doneThemRetrieval = props.doneThemRetrieval;
  console.log("DONE THEM: ", doneThemRetrieval);
  return (
    <div class="grid grid-rows-1 grid-flow-col gap-4 overflow-auto">
      {doneThem.map((item, index) => (
        <div
          onClick={() => props.handleOnClickDoneThemQuiz(index)}
          key={index}
          className="quiz-div w-[360px] bg-[#f7f2f9] rounded-[12px] mt-[24px]"
        >
          <div className="inner-my-quizzes flex">
            <div className="quiz_circle_student bg-[#6750A4] rounded-full text-center flex mr-[16px]">
              <label className="text-white">
                {item.quiz_type === 1 ? "F" : item.quiz_type === 2 ? "M" : "E"}
              </label>
            </div>
            <div className="w-[75%]">
              <div className="dashboard-my-quizzes-name text-with-dots">
                {item.quiz_title}
              </div>
              <div className="dashboard-my-quizzes-topic text-with-dots">
                {item.subject_name}
              </div>
            </div>
          </div>
          <div
            className="dashboard-my-quizzes-topic text-with-dots mt-[25px] ml-[20px]"
            style={{ display: "flex" }}
          >
            <div className="mr-[8px]">
              <img src={Inventory} alt="Quiz Icon" />
            </div>
            <div className="width-full">
              {"Marks: " +
                Math.round((item.quiz_total_marks / 100) * item.overall_score) +
                "/" +
                item.quiz_total_marks}
            </div>
          </div>
        </div>
      ))}
      {doneThemRetrieval.map((item, index) => (
        <div
          onClick={() => props.viewQuizRetrieval(item, 2)}
          key={index}
          className="quiz-div w-[360px] bg-[#f7f2f9] rounded-[12px] mt-[24px]"
        >
          <div className="inner-my-quizzes flex">
            <div className="quiz_circle_student bg-[#6750A4] rounded-full text-center flex mr-[16px]">
              <label className="text-white">R</label>
            </div>
            <div className="w-[75%]">
              <div className="dashboard-my-quizzes-name text-with-dots">
                {item.student_quiz_title}
              </div>
              <div className="dashboard-my-quizzes-topic text-with-dots">
                {item.subject_name}
              </div>
            </div>
          </div>
          <div
            className="dashboard-my-quizzes-topic text-with-dots mt-[25px] ml-[20px]"
            style={{ display: "flex" }}
          >
            <div className="mr-[8px]">
              <img src={Inventory} alt="Quiz Icon" />
            </div>
            <div className="width-full">
              {"Marks: " + item.obtained_score + "/" + item.quiz_marks}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default StudentDoneThemCard;
