import React from "react";
import Quiz from "../../../images/quiz.png";

function StudentDoTheseCard(props) {
  const doThese = props.doThese;
  return (
    <div className="mt-[42px]">
      <div className="  items-center">
        <div>
          {" "}
          <div class="grid xlg:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 mt-0  w-full">
            {doThese.map((item, index) => (
              <div key={index} className="quiz-div bg-[#f7f2f9] rounded-[12px] mt-[24px] mr-[80px] w-[350px]" onClick={() => props.viewQuiz(item, 1)}>
                <div className="inner-my-quizzes flex">
                  <div className="quiz_circle_student bg-[#6750A4] rounded-full text-center flex mr-[16px]">
                    <label className="text-white">{item.quiz_type === 1 ? "F" : item.quiz_type === 2 ? "M" : item.quiz_type === 3 ? "E" : "R"}</label>
                  </div>
                  <div className="w-[75%]">
                    <div className="dashboard-my-quizzes-name text-with-dots">{props.quizType === 2 ? item.student_quiz_title : item.quiz_title}</div>
                    <div className="dashboard-my-quizzes-topic text-with-dots">{item.subject_name}</div>
                  </div>
                </div>
                <div className="dashboard-my-quizzes-topic text-with-dots mt-[25px] ml-[20px]" style={{ display: "flex" }}>
                  <div className="mr-[8px]">
                    <img src={Quiz} alt="Quiz Icon" />
                  </div>
                  <div className="width-full">
                    {props.quizType === 1
                      ? item.quiz_type === 2
                        ? "Mini-mock exam"
                        : item.quiz_type === 1
                        ? "Formative"
                        : "Exam-style"
                      : "Retrieval"}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default StudentDoTheseCard;
