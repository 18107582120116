import React from "react";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from "react-router-dom";
function QuizResult({
  quizScore,
  quizTotalMarks,
  submitQuiz,
  quizType,
  quizMarksReview,
  nextQuiz_id,
  nextQuiz,
  handleSelectedIndex,
  currentIndex,
  handleClickQuizReview,
  sidebarData,
  setNextQuiz,
  handleLessonClick,
  setOpenAccordian,
  setOpenSubtopicAccordian,
  setFirstIteration,
  state,
  quizOrLesson,
  handleQuizClick,
  getSidebarData,
  isQuizSubmitted,
  selectedTopicId,
  selectedSubTopicId,
}) {
  const navigate = useNavigate();

  const handleStrokeColor = () => {
    let percentage = ((quizType === 1 ? quizScore : quizMarksReview.current) / quizTotalMarks) * 100;
    if (percentage < 50) {
      return "red";
    } else if (percentage >= 50 && percentage <= 70) {
      return "#F6CA30";
    } else if (percentage > 70) {
      return "green";
    }
  };

  const navigateBackToClass = () => {
    navigate(-1);
  };
  // const handleNextQuiz = () => {
  //   let foundContent = false;

  //   if (
  //     sidebarData &&
  //     sidebarData.topic_list.length > 0 &&
  //     selectedTopicId.current != null
  //   ) {
  //     const startIndex = sidebarData.topic_list.findIndex(
  //       (topic) => topic.topic_id === selectedTopicId.current
  //     );

  //     if (startIndex === -1) {
  //       alert("Selected topic not found.");
  //       return;
  //     }

  //     let topicIndex = startIndex;

  //     // Loop through topics starting from the current topic
  //     while (topicIndex < sidebarData.topic_list.length && !foundContent) {
  //       const topic = sidebarData.topic_list[topicIndex];
  //       let subtopicIndex = 0;

  //       if (selectedSubTopicId.current) {
  //         subtopicIndex = topic.sub_topic_list.findIndex(
  //           (subtopic) => subtopic.sub_topic_id === selectedSubTopicId.current
  //         );
  //         subtopicIndex = subtopicIndex >= 0 ? subtopicIndex : 0;
  //       }

  //       // Loop through subtopics starting from the current or first subtopic
  //       while (subtopicIndex < topic.sub_topic_list.length && !foundContent) {
  //         const subtopic = topic.sub_topic_list[subtopicIndex];

  //         // Check lessons within the subtopic
  //         for (
  //           let lessonIndex = 0;
  //           lessonIndex < subtopic.lesson_list.length;
  //           lessonIndex++
  //         ) {
  //           const lesson = subtopic.lesson_list[lessonIndex];
  //           if (lesson.lesson_state === 0) {
  //             handleLessonClick(lesson);
  //             setOpenAccordian(topicIndex);
  //             handleSelectedIndex(lesson.lesson_key, lesson, 1);
  //             setOpenSubtopicAccordian(subtopicIndex);
  //             setFirstIteration(true);
  //             state.setLockedContent(false);
  //             foundContent = true;
  //             break;
  //           }
  //         }

  //         // Check quizzes within the subtopic if no lesson is found
  //         if (!foundContent) {
  //           for (
  //             let quizIndex = 0;
  //             quizIndex < subtopic.quizzes_list.length;
  //             quizIndex++
  //           ) {
  //             const quiz = subtopic.quizzes_list[quizIndex];
  //             if (quiz.quiz_state === 0) {
  //               handleQuizClick(quiz);
  //               setOpenAccordian(topicIndex);
  //               handleSelectedIndex(quiz.student_quiz_id, quiz, 2);
  //               setOpenSubtopicAccordian(subtopicIndex);
  //               setFirstIteration(true);
  //               state.setLockedContent(false);
  //               foundContent = true;
  //               break;
  //             }
  //           }
  //         }

  //         // Move to the next subtopic if no content found in current subtopic
  //         if (!foundContent) {
  //           subtopicIndex++;
  //         }
  //       }

  //       // Move to the next topic if no content found in any subtopics of current topic
  //       if (!foundContent && subtopicIndex >= topic.sub_topic_list.length) {
  //         topicIndex++;
  //         selectedSubTopicId.current = undefined; // Reset subtopic ID for the new topic
  //       }
  //     }

  //     // Alert if no content is found after all topics and subtopics are checked
  //     if (!foundContent) {
  //       alert("No available lessons or quizzes found in any topics.");
  //     }
  //   }
  // };

  const style = {
    width: 250,
    display: "inline-block",
    marginRight: 20,
    marginLeft: 20,
    position: "relative",
  };
  return (
    <div className="text-center mt-[15%]">
      <div style={style}>
        <Progress.Circle
          classPrefix="progress"
          gapDegree={60}
          strokeLinecap="butt"
          percent={((quizType === 1 ? quizScore : quizMarksReview.current) / quizTotalMarks) * 100}
          trailColor="#E1DAE3A8"
          trailWidth={5}
          strokeWidth={5}
          gapPosition="bottom"
          strokeColor={handleStrokeColor()}
          showInfo={false}
        />
        <div className="rs-progress-circle-info" style={{ top: "45px" }}>
          <div className="score-text">{`${quizType === 1 ? quizScore : quizMarksReview.current}/${quizTotalMarks}`}</div>
          <div className="score-sub-text mt-[8%]">Score</div>
        </div>
      </div>
      <div>
        <div className="quiz-completed-text text-center mt-[2%]">Quiz Completed!</div>
        <div className="quiz-completed-sub-text mt-[3%]">Every quiz takes you closer to mastery. Keep going! </div>
        <button
          style={{
            display: isQuizSubmitted?.current === undefined || isQuizSubmitted?.current === null || !isQuizSubmitted?.current ? "" : "none",
          }}
          onClick={navigateBackToClass}
          className="enabled-submit-button mt-[3%]"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default QuizResult;
