import React, { useContext, useState } from "react";
import appContext from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import Person from "../../images/person.png";
import amplitude from "amplitude-js";
import delete_icon from "../../images/delete.png";
import more_icon from "../../images/more.svg";
import axios from "axios";
import { Menu, MenuItem, IconButton } from "@mui/material";
import ClassroomDeletedDialog from "./confirmationdialogue/ClassroomDeletedDialog";
import DeleteClassroomDialog from "./confirmationdialogue/DeleteClassroomDialog";

function DashboardClassroomCard({ classroomData, setClassroomList, setOpenJoinClassroomDialogue, handleClick }) {
  const state = useContext(appContext);
  const navigate = useNavigate();

  // Manage modal state
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState(null); // Store selected classroom
  const [anchorEl, setAnchorEl] = useState(null); // For MUI Menu
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };
  // View class handler
  const handleViewClass = (classroom) => {
    localStorage.setItem("teacher_table_state", 1);
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("ViewClass");
    navigate(`/teacher-classroom-home?id=${classroom.classroom_id}`);
  };

  // Handle classroom creation
  // const handleClick = () => {
  //   state.handleClickOpenCreateClassroomDialogue(true);
  // };

  // Delete classroom logic
  const handleDeleteClick = () => {
    if (!selectedClassroom) {
      console.error("No classroom selected for deletion.");
      return;
    }
    setDeleteModalOpen(true);
    setAnchorEl(null); // Close the menu
  };

  const handleDeleteConfirm = async () => {
    if (!selectedClassroom) {
      console.error("No classroom selected for deletion.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/delete_class`,
        {
          class_id: selectedClassroom.classroom_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      // Remove the deleted classroom from the list
      const updatedClassroomList = classroomData.filter((classroom) => classroom.classroom_id !== selectedClassroom.classroom_id);

      setClassroomList(updatedClassroomList);
      setDeleteModalOpen(false);
      setSelectedClassroom(null); // Reset selected classroom after deletion
      setDialogOpen(true);
    } catch (error) {
      console.error("Error deleting classroom:", error.response || error);
      alert("Error deleting classroom. Please try again.");
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setSelectedClassroom(null); // Clear the selected classroom after cancel
  };

  // MUI Menu Handlers
  const handleMenuOpen = (event, classroom) => {
    setAnchorEl(event.currentTarget);
    setSelectedClassroom(classroom); // Set the correct classroom when menu opens
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedClassroom(null); // Clear selected classroom on menu close
  };

  return (
    <div>
      <ClassroomDeletedDialog open={dialogOpen} onClose={handleClose} />
      {classroomData.length === 0 ? (
        <div className="no-classroom-container text-center">
          <div className="no-quiz-label">Create a new classroom to begin or join an existing one to collaborate with others.</div>
          <div className="text-center mt-[2vh] " style={{ display: classroomData?.length === 0 && "none" }}>
            <button type="button" className="create_quiz_button_dashboard-new" onClick={handleClick}>
              <div className="inline-flex">
                👉 <span className="ml-[4px]">Create Your First Class!</span>
              </div>
            </button>
          </div>
          {/* <div className="text-center mt-[2vh] ">
            <button
              type="button"
              className={classroomData?.length === 0 ? `create_quiz_button_dashboard-new` : `create_quiz_button_dashboard-new2`}
              onClick={() => {
                setOpenJoinClassroomDialogue(true);
              }}
            >
              <div className="inline-flex">Join Shared Class</div>
            </button>
          </div> */}
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div className="grid xlg:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-0 w-full">
            {classroomData.map((item, index) => (
              <div className="quiz-div" style={{ height: "auto" }} key={index}>
                <div className="inner-my-quizzes">
                  {/* First Row: Class name at the left and more icon at the right */}
                  <div className="flex justify-between items-center">
                    {/* Class Name at Left */}
                    <span className="dashboard-my-quizzes-name text-with-dots">{item.classroom_name}</span>

                    {/* Three Dots Icon at Right */}
                    <div className="relative mt-[-8px]">
                      <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                        <img className="h-[14px] w-[14px]" src={more_icon} alt="more options" />
                      </IconButton>

                      {/* Dropdown Menu */}
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        PaperProps={{
                          style: {
                            backgroundColor: "#FFFBFE", // Use 'style' attribute for background color
                            boxShadow: "0 0px 0px rgba(0, 0, 0, 0.0)", // Adjust shadow if necessary
                            paddingTop: "0",
                            marginTop: "0",
                            border: "0.05px solid rgba(0, 0, 0, 0.1)",
                          },
                        }}
                      >
                        <MenuItem onClick={handleDeleteClick}>
                          <img className="mr-2 h-[14px] w-[14px]" src={delete_icon} alt="delete" />
                          Delete Classroom
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>

                  {/* Second Row: Date at left, Student icon and count at right */}
                  <div className="flex justify-between items-center mt-[-3px] mb-4">
                    {/* Classroom Creation Time at Left */}
                    <span className="dashboard-my-quizzes-date">{item.classroom_craetion_time}</span>

                    {/* Student Icon and Count at Right */}
                    <div className="flex items-center mr-3">
                      <img
                        src={Person}
                        alt="views"
                        className="pr-1 h-[16px] w-[16px] object-contain" // Adjusted height and width for consistency
                      />
                      <span className=" text-sm">{item.enrolled_student}</span>
                    </div>
                  </div>
                </div>

                {/* Subject and Class Code Section */}
                <div className="dashboard-my-quizzes-info mt-1">
                  <div className="dashboard-my-quizzes-subject text-with-dots pt-[10px]">{item.subject_name}</div>
                  <div className="dashboard-my-quizzes-topic text-with-dots mb-[40px]">
                    <div>Class Code: {item.classroom_code}</div>
                  </div>
                </div>

                {/* View Class Button */}
                <div className="my-quizzes-card_button pt-0 pl-[25px]">
                  <button onClick={() => handleViewClass(item)} className="my-quizzes-card-buttons my-quizzes-card-view-result-button">
                    View Class
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      <DeleteClassroomDialog
        open={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        handleDeleteConfirm={handleDeleteConfirm}
        handleDeleteCancel={handleDeleteCancel}
      />
    </div>
  );
}

export default DashboardClassroomCard;
