import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications"; // for the request sent dialog

export default function CustomDialog({ open, onClose, onTryAgain, dialogType }) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "500px",
          height: dialogType === 0 ? "228px" : "224px", // Adjusting height based on content
          padding: "4px",
        },
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="custom-dialog"
    >
      <DialogTitle
        id="custom-dialog"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // space between title and close icon
          fontFamily: "Roboto",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {dialogType === 0 ? (
            <>
              🚫 <b className="ml-1">Invalid Class Code!</b>
            </>
          ) : (
            <>
              🔔 <b className="ml-1">Request Sent</b>
            </>
          )}
        </div>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            padding: 0,
            top: "-16px",
            right: "-20px", // removes extra padding from button
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography>
          <span className="text-[14px] text-[#49454F] ">
            {dialogType === 0
              ? "We couldn't find a class with that code. Please verify the code and try again."
              : "Your request is under review by the class owner. You will be notified by email once approved."}
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        {dialogType === 0 ? (
          <>
            <Button
              onClick={onClose}
              style={{
                color: "#6750A4",
                fontFamily: "Roboto",
                border: "1px solid #6750A4",
                textTransform: "none", // prevent capitalization
                marginRight: "8px", // space between buttons
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onTryAgain}
              style={{
                backgroundColor: "#6750A4",
                fontFamily: "Roboto",
                color: "#fff",
                textTransform: "none", // prevent capitalization
              }}
              variant="contained"
            >
              Try again
            </Button>
          </>
        ) : (
          <Button
            onClick={onClose}
            style={{
              backgroundColor: "#6750A4",
              fontFamily: "Roboto",
              color: "#fff",
              textTransform: "none", // prevent capitalization
            }}
            variant="contained"
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
