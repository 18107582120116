import React from "react";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font, Image } from "@react-pdf/renderer";

// Register a font (if you need a custom font)
// Font.register({ family: 'Open Sans', src: 'path/to/font.ttf' });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 10,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  detailText: {
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  questionBlock: {
    marginBottom: 10,
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Helvetica",
  },
  answer: {
    marginTop: 8,
    marginBottom: 4,
  },
  subHeading: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "Helvetica",
    marginBottom: 2,
  },
  normalText: {
    fontSize: 10,
    fontFamily: "Helvetica",
    marginBottom: 2,
  },
});

// Create Document Component
const QuizReviewDocument = ({ quizData, student_name, marks_achieved, percentage, quiz_total_marks, isAnswerRubric }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Task Detail Review</Text>
        <View style={styles.detailRow}>
          <Text style={styles.detailText}>Student Name: {student_name}</Text>
          <Text style={styles.detailText}>Marks Achieved: {marks_achieved + "/" + quiz_total_marks}</Text>
        </View>
      </View>

      {quizData.map((quiz, index) => (
        <View key={index} style={styles.questionBlock}>
          <Text style={styles.questionTitle}>
            Question {index + 1}: {JSON.parse(quiz.quiz_question).question.replace(/<\/?[^>]+(>|$)/g, "")} {quiz.score}/{quiz.question_marks}
          </Text>

          <View style={styles.answer}>
            <Text style={styles.subHeading}>Student Answer:</Text>
            <View
              style={{
                backgroundColor: "#EAD1DC",
                border: "1px solid black", // This line adds a border
                padding: 10, // Optional: to ensure content isn't too close to the border
              }}
            >
              <Text style={styles.normalText}>{quiz.student_response}</Text>
            </View>
          </View>

          <View style={styles.answer}>
            <Text style={styles.subHeading}>Teepee:</Text>
            <View
              style={{
                backgroundColor: "#EAD1DC",
                border: "1px solid black", // This line adds a border
                padding: 10, // Optional: to ensure content isn't too close to the border
              }}
            >
              <Text style={styles.normalText}>{quiz.feedback}</Text>
            </View>
          </View>
          {isAnswerRubric === 0 && (
            <View style={styles.answer}>
              <Text style={styles.subHeading}>Actual Answer:</Text>
              <Text style={styles.normalText}>{JSON.parse(quiz.quiz_question).answer}</Text>
            </View>
          )}
        </View>
      ))}
    </Page>
  </Document>
);

export default QuizReviewDocument;
